import React from 'react';
import {
    Button
} from '@material-ui/core';

import useStyles from './SubMenuPage.styles';
import Text from '../../../components/Text';

const SubMenuTabBar = ({
    subMenu,
    setSubMenu,
    creditLineId
}) => {
    const classes = useStyles();
    return (
        <div className={classes.subMenuSectionSelector}>
            <Button
                className={classes.buttonSubMenuContainer}
                onClick={() => setSubMenu('general')}
            >
                <Text 
                    className={subMenu === 'general' ? classes.subMenuTextOn : classes.subMenuTextOff }
                >
                    Información General
                </Text>
            </Button>            
            <Button
                className={classes.buttonSubMenuContainer}
                onClick={() => setSubMenu('transactions')}
            >
                <Text 
                    className={subMenu === 'transactions' ? classes.subMenuTextOn : classes.subMenuTextOff }
                >
                    Transacciones
                </Text>
            </Button>
            {   creditLineId !== 'undefined' &&
                <Button
                    className={classes.buttonSubMenuContainer}
                    onClick={() => setSubMenu('creditInformation')}
                >
                    <Text 
                        className={subMenu === 'creditInformation' ? classes.subMenuTextOn : classes.subMenuTextOff }
                    >
                        Información del crédito
                    </Text>
                </Button>
            }
            {   creditLineId !== 'undefined' &&
                <Button
                    onClick={() => setSubMenu('historyBlocked')}
                >
                    <Text 
                        className={subMenu === 'historyBlocked' ? classes.subMenuTextOn : classes.subMenuTextOff }
                    >
                        Historico de bloqueo
                    </Text>
                </Button>
            }
            {   creditLineId !== 'undefined' &&
                <Button
                    onClick={() => setSubMenu('lateAmount')}
                >
                    <Text 
                        className={subMenu === 'lateAmount' ? classes.subMenuTextOn : classes.subMenuTextOff }
                    >
                        Mora
                    </Text>
                </Button>
            }
            {   creditLineId !== 'undefined' &&
                <Button
                    onClick={() => setSubMenu('paymentAgreements')}
                >
                    <Text 
                        className={subMenu === 'paymentAgreements' ? classes.subMenuTextOn : classes.subMenuTextOff }
                    >
                        Cobranza
                    </Text>
                </Button>
            }
        </div>
    )
}

export default SubMenuTabBar;