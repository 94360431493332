export const INIT_GET_INVENTORY = 'INVENTORY::INIT_GET_INVENTORY';
export const RESPONSE_GET_INVENTORY = 'INVENTORY::RESPONSE_GET_INVENTORY';
export const ERROR_GET_INVENTORY = 'INVENTORY::ERROR_GET_INVENTORY';
export const CLEAR_GET_INVENTORY = 'INVENTORY::CLEAR_GET_INVENTORY';

export const INIT_GET_INVENTORY_COMPARISON = 'INVENTORY::INIT_GET_INVENTORY_COMPARISON';
export const RESPONSE_GET_INVENTORY_COMPARISON = 'INVENTORY::RESPONSE_GET_INVENTORY_COMPARISON';
export const ERROR_GET_INVENTORY_COMPARISON = 'INVENTORY::ERROR_GET_INVENTORY_COMPARISON';
export const CLEAR_GET_INVENTORY_COMPARISON = 'INVENTORY::CLEAR_GET_INVENTORY_COMPARISON';

export const INIT_POST_REEVALUATE_INVENTORY = 'INVENTORY::INIT_POST_REEVALUATE_INVENTORY';
export const RESPONSE_POST_REEVALUATE_INVENTORY = 'INVENTORY::RESPONSE_POST_REEVALUATE_INVENTORY ';
export const ERROR_POST_REEVALUATE_INVENTORY = 'INVENTORY::ERROR_POST_REEVALUATE_INVENTORY';
export const CLEAR_POST_REEVALUATE_INVENTORY = 'INVENTORY::CLEAR_POST_REEVALUATE_INVENTORY';

export const INIT_GET_INVENTORY_VERSION = 'INVENTORY::INIT_GET_INVENTORY_VERSION';
export const RESPONSE_GET_INVENTORY_VERSION = 'INVENTORY::RESPONSE_GET_INVENTORY_VERSION';
export const ERROR_GET_INVENTORY_VERSION = 'INVENTORY::ERROR_GET_INVENTORY_VERSION';
export const CLEAR_GET_INVENTORY_VERSION = 'INVENTORY::CLEAR_GET_INVENTORY_VERSION';

export const INIT_GET_INVENTORY_DOCUMENTS = 'INVENTORY::INIT_GET_INVENTORY_DOCUMENTS';
export const RESPONSE_GET_INVENTORY_DOCUMENTS = 'INVENTORY::RESPONSE_GET_INVENTORY_DOCUMENTS';
export const ERROR_GET_INVENTORY_DOCUMENTS = 'INVENTORY::ERROR_GET_INVENTORY_DOCUMENTS';
export const CLEAR_GET_INVENTORY_DOCUMENTS = 'INVENTORY::CLEAR_GET_INVENTORY_DOCUMENTS';

export const INIT_POST_INVENTORY_VERSION_CLOSING = 'INVENTORY::INIT_POST_INVENTORY_VERSION_CLOSING';
export const RESPONSE_POST_INVENTORY_VERSION_CLOSING = 'INVENTORY::RESPONSE_POST_INVENTORY_VERSION_CLOSING';
export const ERROR_POST_INVENTORY_VERSION_CLOSING = 'INVENTORY::ERROR_POST_INVENTORY_VERSION_CLOSING';
export const CLEAR_POST_INVENTORY_VERSION_CLOSING = 'INVENTORY::CLEAR_POST_INVENTORY_VERSION_CLOSING';

export const INIT_PUT_IS_VALID_INVENTORY_PHOTOS = 'INVENTORY::INIT_PUT_IS_VALID_INVENTORY_PHOTOS';
export const RESPONSE_PUT_IS_VALID_INVENTORY_PHOTOS = 'INVENTORY::RESPONSE_PUT_IS_VALID_INVENTORY_PHOTOS';
export const ERROR_PUT_IS_VALID_INVENTORY_PHOTOS = 'INVENTORY::ERROR_PUT_IS_VALID_INVENTORY_PHOTOS';
export const CLEAR_PUT_IS_VALID_INVENTORY_PHOTOS = 'INVENTORY::CLEAR_PUT_IS_VALID_INVENTORY_PHOTOS';

export const INIT_PUT_INVENTORY_PHOTOS_CATEGORY = 'INVENTORY::INIT_PUT_INVENTORY_PHOTOS_CATEGORY';
export const RESPONSE_PUT_INVENTORY_PHOTOS_CATEGORY = 'INVENTORY::RESPONSE_PUT_INVENTORY_PHOTOS_CATEGORY';
export const ERROR_PUT_INVENTORY_PHOTOS_CATEGORY = 'INVENTORY::ERROR_PUT_INVENTORY_PHOTOS_CATEGORY';
export const CLEAR_PUT_INVENTORY_PHOTOS_CATEGORY = 'INVENTORY::CLEAR_PUT_INVENTORY_PHOTOS_CATEGORY';

export const INIT_DELETE_INVENTORY_PHOTOS_CATEGORY = 'INVENTORY::INIT_DELETE_INVENTORY_PHOTOS_CATEGORY';
export const RESPONSE_DELETE_INVENTORY_PHOTOS_CATEGORY = 'INVENTORY::RESPONSE_DELETE_INVENTORY_PHOTOS_CATEGORY';
export const ERROR_DELETE_INVENTORY_PHOTOS_CATEGORY = 'INVENTORY::ERROR_DELETE_INVENTORY_PHOTOS_CATEGORY';
export const CLEAR_DELETE_INVENTORY_PHOTOS_CATEGORY = 'INVENTORY::CLEAR_DELETE_INVENTORY_PHOTOS_CATEGORY';

// Task
export const INIT_POST_TASK_ASSIGNMENT = 'INVENTORY::INIT_POST_TASK_ASSIGNMENT';
export const RESPONSE_POST_TASK_ASSIGNMENT = 'INVENTORY::RESPONSE_POST_TASK_ASSIGNMENT';
export const ERROR_POST_TASK_ASSIGNMENT = 'INVENTORY::ERROR_POST_TASK_ASSIGNMENT';
export const CLEAR_POST_TASK_ASSIGNMENT = 'INVENTORY::CLEAR_POST_TASK_ASSIGNMENT';

// Closing fill form
export const INIT_POST_INVENTORY_CLOSING_FILL_FORM = 'INVENTORY::INIT_POST_INVENTORY_CLOSING_FILL_FORM';
export const RESPONSE_POST_INVENTORY_CLOSING_FILL_FORM = 'INVENTORY::RESPONSE_POST_INVENTORY_CLOSING_FILL_FORM';
export const ERROR_POST_INVENTORY_CLOSING_FILL_FORM = 'INVENTORY::ERROR_POST_INVENTORY_CLOSING_FILL_FORM';
export const CLEAR_POST_INVENTORY_CLOSING_FILL_FORM = 'INVENTORY::CLEAR_POST_INVENTORY_CLOSING_FILL_FORM';

export const CLEAR_ERRORS = 'INVENTORY::CLEAR_ERRORS';
