import * as ACTION from './creditline.actionTypes';

const creditlineData = {
  loading: {
    GET_EXTENSION_REQUEST: false,
    PUT_EXTENSION_REQUEST: false,
    POST_PARTICULAR_EXTENSION_REQUEST: false,
    GET_LATE_FEES: false,
    DELETE_DISBURSEMENT: false,
    DELETE_INVENTORY_PURCHASE: false,
  },
  error: {
    GET_EXTENSION_REQUEST: null,
    PUT_EXTENSION_REQUEST: null,
    POST_PARTICULAR_EXTENSION_REQUEST: null,
    GET_LATE_FEES: null,
    DELETE_DISBURSEMENT: null,
    DELETE_INVENTORY_PURCHASE: null,
  },
  extensionRequestData: {},
  updateExtensionRequestData: {},
  particularExtensionRequestData: {},
  lateFeesRequestData: {},
  deleteDisbursementData: {},
  deleteInventoryPurchaseData: {},
};

const creditlineReducer = (state = creditlineData, action) => {
  switch (action.type) {
    case ACTION.INIT_GET_EXTENSION_REQUEST:
      return { ...state, loading: { ...state.loading, GET_EXTENSION_REQUEST: true }, error: { ...state.error, GET_EXTENSION_REQUEST: null } };
    case ACTION.RESPONSE_GET_EXTENSION_REQUEST:
      return {
        ...state, loading: { ...state.loading, GET_EXTENSION_REQUEST: false }, error: { ...state.error, GET_EXTENSION_REQUEST: null }, extensionRequestData: action.result,
      };
    case ACTION.ERROR_GET_EXTENSION_REQUEST:
      return { ...state, loading: { ...state.loading, GET_EXTENSION_REQUEST: false }, error: { ...state.error, GET_EXTENSION_REQUEST: action.error } };
    case ACTION.CLEAR_GET_EXTENSION_REQUEST:
      return { ...state, extensionRequestData: {} };

    case ACTION.INIT_PUT_EXTENSION_REQUEST:
      return { ...state, loading: { ...state.loading, PUT_EXTENSION_REQUEST: true }, error: { ...state.error, PUT_EXTENSION_REQUEST: null } };
    case ACTION.RESPONSE_PUT_EXTENSION_REQUEST:
      return {
        ...state, loading: { ...state.loading, PUT_EXTENSION_REQUEST: false }, error: { ...state.error, PUT_EXTENSION_REQUEST: null }, updateExtensionRequestData: action.result,
      };
    case ACTION.ERROR_PUT_EXTENSION_REQUEST:
      return { ...state, loading: { ...state.loading, PUT_EXTENSION_REQUEST: false }, error: { ...state.error, PUT_EXTENSION_REQUEST: action.error } };
    case ACTION.CLEAR_PUT_EXTENSION_REQUEST:
      return { ...state, updateExtensionRequestData: {} };

    case ACTION.INIT_POST_PARTICULAR_EXTENSION_REQUEST:
      return { ...state, loading: { ...state.loading, POST_PARTICULAR_EXTENSION_REQUEST: true }, error: { ...state.error, POST_PARTICULAR_EXTENSION_REQUEST: null } };
    case ACTION.RESPONSE_POST_PARTICULAR_EXTENSION_REQUEST:
      return {
        ...state, loading: { ...state.loading, POST_PARTICULAR_EXTENSION_REQUEST: false }, error: { ...state.error, PUT_EXTENSION_REQUEST: null }, particularExtensionRequestData: action.result,
      };
    case ACTION.ERROR_POST_PARTICULAR_EXTENSION_REQUEST:
      return { ...state, loading: { ...state.loading, POST_PARTICULAR_EXTENSION_REQUEST: false }, error: { ...state.error, POST_PARTICULAR_EXTENSION_REQUEST: action.error } };
    case ACTION.CLEAR_POST_PARTICULAR_EXTENSION_REQUEST:
      return { ...state, particularExtensionRequestData: {} };

    case ACTION.INIT_GET_LATE_FEES:
      return { ...state, loading: { ...state.loading, GET_LATE_FEES: true }, error: { ...state.error, GET_LATE_FEES: null } };
    case ACTION.RESPONSE_GET_LATE_FEES:
      return {
        ...state, loading: { ...state.loading, GET_LATE_FEES: false }, error: { ...state.error, GET_LATE_FEES: null }, lateFeesRequestData: action.result,
      };
    case ACTION.ERROR_GET_LATE_FEES:
      return { ...state, loading: { ...state.loading, GET_LATE_FEES: false }, error: { ...state.error, GET_LATE_FEES: action.error } };
    case ACTION.CLEAR_GET_LATE_FEES:
      return { ...state, lateFeesRequestData: {} };

    case ACTION.INIT_DELETE_DISBURSEMENT:
      return { ...state, loading: { ...state.loading, DELETE_DISBURSEMENT: true }, error: { ...state.error, DELETE_DISBURSEMENT: null } };
    case ACTION.RESPONSE_DELETE_DISBURSEMENT:
      return {
        ...state, loading: { ...state.loading, DELETE_DISBURSEMENT: false }, error: { ...state.error, DELETE_DISBURSEMENT: null }, deleteDisbursementData: action.result,
      };
    case ACTION.ERROR_DELETE_DISBURSEMENT:
      return { ...state, loading: { ...state.loading, DELETE_DISBURSEMENT: false }, error: { ...state.error, DELETE_DISBURSEMENT: action.error } };
    case ACTION.CLEAR_DELETE_DISBURSEMENT:
      return { ...state, deleteDisbursementData: {} };

    case ACTION.INIT_DELETE_INVENTORY_PURCHASE:
      return { ...state, loading: { ...state.loading, DELETE_INVENTORY_PURCHASE: true }, error: { ...state.error, DELETE_INVENTORY_PURCHASE: null } };
    case ACTION.RESPONSE_DELETE_INVENTORY_PURCHASE:
      return {
        ...state, loading: { ...state.loading, DELETE_INVENTORY_PURCHASE: false }, error: { ...state.error, DELETE_INVENTORY_PURCHASE: null }, deleteInventoryPurchaseData: action.result,
      };
    case ACTION.ERROR_DELETE_INVENTORY_PURCHASE:
      return { ...state, loading: { ...state.loading, DELETE_INVENTORY_PURCHASE: false }, error: { ...state.error, DELETE_INVENTORY_PURCHASE: action.error } };
    case ACTION.CLEAR_DELETE_INVENTORY_PURCHASE:
      return { ...state, deleteInventoryPurchaseData: {} };

    case ACTION.CLEAR_ERRORS:
      return { ...state, error: creditlineData.error };
    default:
      return state;
  }
};

export default creditlineReducer;
