import React, { useState } from 'react';
import Button from '../Button';
import { Box } from '../Layout';
import {
  CircularProgress, FormHelperText
} from '@material-ui/core';
import { appService } from '../../services/app.service';

const PdfDownloader = (props) => {
  const {
    url,
    filename,
    label
  } = props
  // setup the ref that we'll use for the hidden CsvLink click once we've updated the data
  const [showSpinner, setShowSpinner] = useState('none');
  const [errorMsg, setErrorMsg] = useState('');

  const downloadFile = async () => {
    setShowSpinner('block');
    setErrorMsg('');

    const basePath = appService.config.paths.api.basePath;
    const token = localStorage.getItem('firebaseToken');
    fetch(basePath + url, {
      method: "GET",
      headers: {
        "Authorization": token
      },
      timeout: 600000,
    })
      .then(response => {
        response.arrayBuffer().then(buffer => {
          setTimeout(() => setShowSpinner('none'), 100);
          const blob = new Blob([buffer], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = filename;
          link.click();
        });
      })
      .catch(e => {
        console.error(e);
        setTimeout(() => setShowSpinner('none'), 100);
        setErrorMsg(`${e.message} - ${e.response.data.detail || e.response.data.message || ''}`);
      });
  };

  // more code here

  return (
    // a bunch of other code here...
    <Box style={{ display: 'flex', margin: '5px 10px' }}>
      <Box style={{ display: 'flex' }}>
        <Button onClick={downloadFile}>{label}</Button>
        <Box style={{ display: showSpinner }}>
          <CircularProgress />
        </Box>
      </Box>
      <FormHelperText>
        {errorMsg}
      </FormHelperText>
    </Box>
  );
};

export default PdfDownloader;
