import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box
} from '@material-ui/core';
import Switch from '@material-ui/core/Switch'
import moment from 'moment';
import Modal from '@material-ui/core/Modal';
import CancelIcon from '@material-ui/icons/Close';
import InputBase from '@material-ui/core/InputBase';
import Checkbox from '@material-ui/core/Checkbox';

import useStyles from '../SubMenuPage.styles';
import Text from '../../../../components/Text';
import HistoryTable from './HistoryTable'
import { getHistoryBlocked } from '../CreditInformation/CreditInformation.actions';
import { preferredPaymentDayOptions } from '../../../../constants/constants';
import Button from '../../../../components/Button'

const History = ({ creditLineId }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    
    const {
        blocked_shop_detail,
        error
    } = useSelector((state) => state.creditInformationState);

    useEffect(() => {
        dispatch(getHistoryBlocked(creditLineId));
    }, [])

    return (
        <Box className={classes.tableContainer}>
            <HistoryTable blocked_shop_detail={ blocked_shop_detail } />
        </Box>
    )
}

export default History;
