import * as ACTION from '../../../../reducers/creditInformation.actionTypes';
import shopsService from '../../../../services/shops.service';

  export const getCreditDetail = (creditLineId, queries) => async (dispatch, getState) => {
    dispatch({ type: ACTION.FETCH_SHOPBYID_INIT });

    try {
      const shopDetail = await shopsService.getCreditLineShopById(creditLineId, queries);
      if (shopDetail) {
        dispatch({
          type: ACTION.FETCH_SHOPBYID_SUCCESS
        });
        dispatch({
          type: ACTION.SET_STATE,
          payload: {
            label: 'credit_line_shop_detail',
            value: shopDetail,
          },
        });
      } else {
        dispatch({
          type: ACTION.FETCH_SHOPBYID_ERROR,
        });
      }
    } catch (e) {
      dispatch({
        type: ACTION.FETCH_SHOPBYID_ERROR,
      });
    }
  };

  export const getNextPage = () => (dispatch, getState) => {
    const { page } = getState().registersState;
    dispatch(getCreditDetail(page + 1));
  };

  export const getPreviousPage = () => (dispatch, getState) => {
    const { page } = getState().registersState;
    dispatch(getCreditDetail(page - 1));
  };

  export const setAllowDisbursements = (creditLineId, body) => (dispatch, getState) => {
    try {
      shopsService.setAllowDisbursement(creditLineId, body);
    } catch (e) {
      dispatch({
        type: ACTION.FETCH_SHOPBYID_ERROR,
      });
    }
  };

  export const setAllowExtension = (creditLineId, value) => (dispatch, getState) => {
    try {
      shopsService.setAllowExtension(creditLineId, value);
    } catch (e) {
      dispatch({
        type: ACTION.FETCH_SHOPBYID_ERROR,
      });
    }
  };

  export const getHistoryBlocked = (creditLineId, queries) => async (dispatch, getState) => {
    dispatch({ type: ACTION.FETCH_BLOCKED_BYID_INIT });

    try {
      const shopBlocked = await shopsService.getShopBlockedById(creditLineId, queries);
      if (shopBlocked) {
        dispatch({
          type: ACTION.FETCH_BLOCKED_BYID_SUCCESS
        });
        dispatch({
          type: ACTION.SET_STATE,
          payload: {
            label: 'blocked_shop_detail',
            value: shopBlocked,
          },
        });
      } else {
        dispatch({
          type: ACTION.FETCH_BLOCKED_BYID_ERROR,
        });
      }
    } catch (e) {
      dispatch({
        type: ACTION.FETCH_BLOCKED_BYID_ERROR,
      });
    }
  };
