import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box
} from '@material-ui/core';
import Switch from '@material-ui/core/Switch'
import moment from 'moment';
import Modal from '@material-ui/core/Modal';
import CancelIcon from '@material-ui/icons/Close';
import InputBase from '@material-ui/core/InputBase';
import Checkbox from '@material-ui/core/Checkbox';

import useStyles from '../SubMenuPage.styles';
import Text from '../../../../components/Text';
import CreditInformationTable from './CreditInformationTable'
import { getCreditDetail, setAllowDisbursements, setAllowExtension } from '../CreditInformation/CreditInformation.actions';
import { preferredPaymentDayOptions, paymentDayTitle, termTypeTitles } from '../../../../constants/constants';
import Button from '../../../../components/Button'
import { remainingPayments } from '../../../../constants/remainingPayments';
import PdfDownloader from '../../../../components/PdfDownloader';

const CreditInformationPage = ({ creditLineId }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [checkDisbursement, setCheckDisbursement] = useState(false);
    const [checkSupplier, setCheckSupplier] = useState(false);
    const [checkInventory, setCheckInventory] = useState(false);
    const [checkExtension, setCheckExtension] = useState(false);
    const [showReasonBlocked, setShowReasonBlocked] = useState({
        value: false,
        text: ''
    });
    const [reasons, setReasons] = useState({
        COMPLETE_CLOSURE_OF_THE_BUSINESS: false,
        DIFFICULTY_OF_COLLECTION: false,
        HARD_TO_FIND_CUSTOMER: false,
        INCREASES_IN_PERSONAL_EXPENSES: false,
        INVENTORY_DECREASE: false,
        PARTIAL_CLOSURE_OF_BUSINESS: false,
        SALES_REDUCTION: false,
        UNREACHABLE_CUSTOMER: false,
        RESTRUCTURING: false,
        SICKNESS: false,
        OWNERSHIP_CHANGE: false,
        THEFT_OR_BLACKMAIL: false,
        CREDIT_LINE_FOR_EMPLOYEE: false,
        DEBT_CANCELLATION: false,
    });
    const [descriptionReason, setDescriptionReason] = useState('')

    const {
        isFetchingShopDetail,
        credit_line_shop_detail,
        error
    } = useSelector((state) => state.creditInformationState);

    const nextPaymentDate = (Object.keys(credit_line_shop_detail).length !== 0 && credit_line_shop_detail?.next_payment) && new Date(credit_line_shop_detail.next_payment.deadline_date)
    const formatNextPaymentDate = (Object.keys(credit_line_shop_detail).length !== 0 && credit_line_shop_detail?.next_payment) && moment(credit_line_shop_detail.next_payment.deadline_date || Date.now()).format('DD [de] MMMM');
    let balanceNoPurchases = (credit_line_shop_detail?.balance || 0) - (credit_line_shop_detail?.total_inventory_purchased || 0);
    if (balanceNoPurchases < 0) {
        balanceNoPurchases = 0
    }
    balanceNoPurchases = Math.round(balanceNoPurchases * 100) / 100;
    const rp = remainingPayments(balanceNoPurchases, credit_line_shop_detail.installment, credit_line_shop_detail.interest_rate, credit_line_shop_detail.term_type.toUpperCase());
    useEffect(() => {
        dispatch(getCreditDetail(creditLineId));
    }, [])

    useEffect(() => {
        if (credit_line_shop_detail.allow_disbursements !== undefined) {
            setCheckDisbursement(credit_line_shop_detail.allow_disbursements);
        }
        if (credit_line_shop_detail.allow_supplier_purchases !== undefined) {
            setCheckSupplier(credit_line_shop_detail.allow_supplier_purchases)
        }
        if (credit_line_shop_detail.allow_wholesaler_purchases !== undefined) {
            setCheckInventory(credit_line_shop_detail.allow_wholesaler_purchases)
        }
        if (credit_line_shop_detail.allow_extensions !== undefined) {
            setCheckExtension(credit_line_shop_detail.allow_extensions);
        }
    }, [credit_line_shop_detail])
    const usedBalancePercentage = () => {
        let percentage = balanceNoPurchases * 100 / credit_line_shop_detail.credit_line_amount;
        return `${percentage}%`;
    }

    const usedInventoryPercentage = () => {
        let percentage = (credit_line_shop_detail?.total_inventory_purchased || 0) * 100 / credit_line_shop_detail.credit_line_amount;
        return `${percentage}%`;
    }

    const availableBalancePercentage = () => {
        let percentage = credit_line_shop_detail.available_balance * 100 / credit_line_shop_detail.credit_line_amount;
        return `${percentage}%`;
    }

    const handleChangeDisbursement = (event, val) => {
        setCheckDisbursement(val);
        if (val) {
            const body = {
                allow: true,
                reasons: [],
                description: descriptionReason,
                disbursement_type: 'DISBURSEMENT'
            }
            dispatch(setAllowDisbursements(creditLineId, body));
        } else {
            setShowReasonBlocked({
                value: true,
                text: 'DISBURSEMENT'
            });
        }
    }

    const handleChangeSupplier = (event, val) => {
        setCheckSupplier(val);
        if (val) {
            const body = {
                allow: true,
                reasons: [],
                description: descriptionReason,
                disbursement_type: 'SUPPLIER_PURCHASE'
            }
            dispatch(setAllowDisbursements(creditLineId, body));
        } else {
            setShowReasonBlocked({
                value: true,
                text: 'SUPPLIER_PURCHASE'
            });        
        }
    }

    const handleChangeInventory = (event, val) => {
        setCheckInventory(val);
        if (val) {
            const body = {
                allow: true,
                reasons: [],
                description: descriptionReason,
                disbursement_type: 'INVENTORY_PURCHASE'
            }
            dispatch(setAllowDisbursements(creditLineId, body));
        } else {
            setShowReasonBlocked({
                value: true,
                text: 'INVENTORY_PURCHASE'
            }); 
        }
    }

    const handleChangeExtension = (event, val) => {
        setCheckExtension(val);
        dispatch(setAllowExtension(creditLineId, val));
    }

    const handleChangeCheckbox = (event) => {
        setReasons({ ...reasons, [event.target.name]: event.target.checked });
    };

    const _disallowDisbursment = () => {
        const reasonsArray = [];

        if (reasons.COMPLETE_CLOSURE_OF_THE_BUSINESS) {
            reasonsArray.push('COMPLETE_CLOSURE_OF_THE_BUSINESS')
        }
        if (reasons.DIFFICULTY_OF_COLLECTION) {
            reasonsArray.push('DIFFICULTY_OF_COLLECTION')
        }
        if (reasons.HARD_TO_FIND_CUSTOMER) {
            reasonsArray.push('HARD_TO_FIND_CUSTOMER')
        }
        if (reasons.INCREASES_IN_PERSONAL_EXPENSES) {
            reasonsArray.push('INCREASES_IN_PERSONAL_EXPENSES')
        }
        if (reasons.INVENTORY_DECREASE) {
            reasonsArray.push('INVENTORY_DECREASE')
        }
        if (reasons.PARTIAL_CLOSURE_OF_BUSINESS) {
            reasonsArray.push('PARTIAL_CLOSURE_OF_BUSINESS')
        }
        if (reasons.SALES_REDUCTION) {
            reasonsArray.push('SALES_REDUCTION')
        }
        if (reasons.UNREACHABLE_CUSTOMER) {
            reasonsArray.push('UNREACHABLE_CUSTOMER')
        }
        if (reasons.RESTRUCTURING) {
            reasonsArray.push('RESTRUCTURING')
        }
        if (reasons.SICKNESS) {
            reasonsArray.push('SICKNESS')
        }
        if (reasons.OWNERSHIP_CHANGE) {
            reasonsArray.push('OWNERSHIP_CHANGE')
        }
        if (reasons.THEFT_OR_BLACKMAIL) {
            reasonsArray.push('THEFT_OR_BLACKMAIL')
        }
        if (reasons.CREDIT_LINE_FOR_EMPLOYEE) {
            reasonsArray.push('CREDIT_LINE_FOR_EMPLOYEE')
        }
        if (reasons.DEBT_CANCELLATION) {
            reasonsArray.push('DEBT_CANCELLATION')
        }

        const disbursementType = () => {
            if(showReasonBlocked.text === 'DISBURSEMENT') {
                return 'DISBURSEMENT'
            } else if(showReasonBlocked.text === 'SUPPLIER_PURCHASE') {
                return 'SUPPLIER_PURCHASE'
            } else if(showReasonBlocked.text === 'INVENTORY_PURCHASE') {
                return 'INVENTORY_PURCHASE'
            }
        };

        const body = {
            allow: false,
            reasons: reasonsArray,
            description: descriptionReason,
            disbursement_type: disbursementType()
        }
        dispatch(setAllowDisbursements(creditLineId, body));

        setShowReasonBlocked({
            value: false,
            text: '',
        });
        setReasons({
            COMPLETE_CLOSURE_OF_THE_BUSINESS: false,
            DIFFICULTY_OF_COLLECTION: false,
            HARD_TO_FIND_CUSTOMER: false,
            INCREASES_IN_PERSONAL_EXPENSES: false,
            INVENTORY_DECREASE: false,
            PARTIAL_CLOSURE_OF_BUSINESS: false,
            SALES_REDUCTION: false,
            UNREACHABLE_CUSTOMER: false,
            RESTRUCTURING: false,
            SICKNESS: false,
            OWNERSHIP_CHANGE: false,
            THEFT_OR_BLACKMAIL: false,
            CREDIT_LINE_FOR_EMPLOYEE: false,
            DEBT_CANCELLATION: false,
        });
        setDescriptionReason('');
    }

    return (
        <>
            <div className={classes.creditBar}>
                <div className={classes.creditBarContainer}>
                    <div className={classes.creditBarElementFirst}>
                        <div>
                            <div className={classes.creditBarSubElementFirst} style={{ marginTop: 0 }}>
                                <Text className={classes.informationTextCreditInformation}>L&iacute;mite de la l&iacute;nea:</Text>
                                <Text className={classes.informationTextCreditInformationBold}>${credit_line_shop_detail?.credit_line_amount || '0'}</Text>
                            </div>
                            <div className={classes.creditBarSubElementFirst}>
                                <Text className={classes.informationTextCreditInformation}>Pagos restantes:</Text>
                                <Text className={classes.informationTextCreditInformationBold}>{rp || 0}</Text>
                            </div>
                        </div>
                        <div className={classes.creditBarSubElementSecond}>
                            <div className={classes.creditBarSubElementFirst} style={{ marginTop: 0 }}>
                                <Text className={classes.informationTextCreditInformation}>Pago para liquidar:</Text>
                                <Text className={classes.informationTextCreditInformationBold}>${credit_line_shop_detail?.settlement_amount || '0'}</Text>
                            </div>
                            <div className={classes.creditBarSubElementFirst}>
                                <Text className={classes.informationTextCreditInformation}>D&iacute;a de pago:</Text>
                                <Text className={classes.informationTextCreditInformationBold}>{paymentDayTitle[credit_line_shop_detail?.payment_day || '']}</Text>
                            </div>
                        </div>
                    </div>
                    <div className={classes.creditBarElementSecond}>
                        <div className={classes.creditBarSubElementFirstSecondSubElement}>
                            <div className={classes.graphicCreditBarLeft} style={{
                                width: credit_line_shop_detail ? usedBalancePercentage() : '33%',
                                borderTopRightRadius: usedBalancePercentage() === '100%' ? '7px' : 0,
                                borderBottomRightRadius: usedBalancePercentage() === '100%' ? '7px' : 0
                            }} />
                            <div className={classes.graphicCreditBarMiddle} style={{
                                width: credit_line_shop_detail ? usedInventoryPercentage() : '33%',
                                borderTopRightRadius: usedInventoryPercentage() === '100%' ? '7px' : 0,
                                borderBottomRightRadius: usedInventoryPercentage() === '100%' ? '7px' : 0
                            }} />
                            <div className={classes.graphicCreditBarRight} style={{
                                width: credit_line_shop_detail ? availableBalancePercentage() : '33%',
                                borderTopLeftRadius: availableBalancePercentage() === '100%' ? '7px' : 0,
                                borderBottomLeftRadius: availableBalancePercentage() === '100%' ? '7px' : 0
                            }} />
                        </div>
                        <div className={classes.creditBarSubElementFirst}>
                            <div className={classes.creditBarSubElementFirstSecondElement}>
                                <Text className={classes.informationTextCreditInformation}>Dispuesto:</Text>
                                <Text className={classes.informationTextCreditInformationBold}>${balanceNoPurchases || '0'}</Text>
                            </div>
                            <div className={classes.creditBarSubElementFirstSecondElement}>
                                <Text className={classes.informationTextCreditInformation}>Inventario:</Text>
                                <Text className={classes.informationTextCreditInformationBold}>${credit_line_shop_detail?.total_inventory_purchased || '0'}</Text>
                            </div>
                            <div className={classes.creditBarSubElementFirstSecondSubElement}>
                                <Text className={classes.informationTextCreditInformation}>Disponible:</Text>
                                <Text className={classes.informationTextCreditInformationBold}>${credit_line_shop_detail?.available_balance || '0'}</Text>
                            </div>
                        </div>
                    </div>
                    <div className={classes.creditBarElementFirst}>
                        <div className={classes.creditBarSubElementFirstThirdElement}>
                            <Text className={classes.informationTextCreditInformationThird}>Próximo pago</Text>
                            <Text className={classes.informationTextCreditInformationBoldThird}>
                                ${credit_line_shop_detail?.next_payment ? (credit_line_shop_detail.next_payment.amount).toFixed(2) : '0'}
                            </Text>
                        </div>
                        <div>
                            <Text className={classes.informationTextCreditInformationThird}>
                                {credit_line_shop_detail?.next_payment ? preferredPaymentDayOptions[nextPaymentDate.getDay()].title : '-'}
                            </Text>
                            <Text className={classes.informationTextCreditInformationBoldThird}>
                                {credit_line_shop_detail?.next_payment ? formatNextPaymentDate : '-'}
                            </Text>
                        </div>
                    </div>
                    <div className={classes.creditBarElementFirst}>
                    <div className={classes.creditBarSubElementFirstThirdElement}>
                        <Text className={classes.informationTextCreditInformationThird}>Mora por pagar</Text>
                        <Text className={classes.informationTextCreditInformationBoldThird}>
                            ${credit_line_shop_detail?.total_remaining_late_interest ? (credit_line_shop_detail.total_remaining_late_interest).toFixed(2) : '0'}
                        </Text>
                    </div>
                </div>
                    <div className={classes.creditBarElementFirst}>
                        <div className={classes.creditBarSubElementFirstThirdElement}>
                            <Text className={classes.informationTextCreditInformationThird}>Frecuencia de pago</Text>
                            <Text className={classes.informationTextCreditInformationBoldThird}>
                                {termTypeTitles[credit_line_shop_detail?.term_type.toUpperCase()]}
                            </Text>
                        </div>
                        <div>
                            <Text className={classes.informationTextCreditInformationThird}>Inter&eacute;s Mensual</Text>
                            <Text className={classes.informationTextCreditInformationBoldThird}>
                                {credit_line_shop_detail?.interest_rate || 0}%
                            </Text>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <div className={classes.switchButton}>
                            <Switch checked={checkDisbursement} onChange={handleChangeDisbursement}></Switch>
                            <Text>Permitir dispersión</Text>
                        </div>
                        <div className={classes.switchButton}>
                            <Switch checked={checkSupplier} onChange={handleChangeSupplier}></Switch>
                            <Text>Permitir compra a proveedor</Text>
                        </div>
                        <div className={classes.switchButton}>
                            <Switch checked={checkInventory} onChange={handleChangeInventory}></Switch>
                            <Text>Permitir compra a bodega</Text>
                        </div>
                        <div>
                            <Switch checked={checkExtension} onChange={handleChangeExtension}></Switch>
                            <Text>Recomendar ampliación</Text>
                        </div>
                    </div>
                </div>
            </div>
            <PdfDownloader
                url={`/admin/reports/financial-statement?credit-line-id=${creditLineId}`}
                filename={`estado_de_cuenta_${creditLineId}.pdf`}
                label="Descargar Estado de Cuenta">
            </PdfDownloader>
            <Text className={classes.titleInformationCreditTable}>Tabla de amortización</Text>
            <Box className={classes.tableContainer}>
                <CreditInformationTable credit_line_shop_detail={credit_line_shop_detail} />
            </Box>
            <Modal
                open={showReasonBlocked.value}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className={classes.disallowDisbusment}
            >
                <Box className={classes.containerDisallowDisbusment}>
                    <div className={classes.modalHeaderContainer}>
                        <Text className={classes.modalTitle}>Motivo de bloqueo</Text>
                        <CancelIcon
                            onClick={() => {
                                setShowReasonBlocked({
                                    value: false,
                                    text: ''
                                });
                                if(showReasonBlocked.text === 'DISBURSEMENT') {
                                    setCheckDisbursement(true);
                                } else if(showReasonBlocked.text === 'SUPPLIER_PURCHASE') {
                                    setCheckSupplier(true)
                                } else if(showReasonBlocked.text === 'INVENTORY_PURCHASE') {
                                    setCheckInventory(true)
                                }
                                setReasons({
                                    COMPLETE_CLOSURE_OF_THE_BUSINESS: false,
                                    DIFFICULTY_OF_COLLECTION: false,
                                    HARD_TO_FIND_CUSTOMER: false,
                                    INCREASES_IN_PERSONAL_EXPENSES: false,
                                    INVENTORY_DECREASE: false,
                                    PARTIAL_CLOSURE_OF_BUSINESS: false,
                                    SALES_REDUCTION: false,
                                    UNREACHABLE_CUSTOMER: false,
                                    RESTRUCTURING: false,
                                    SICKNESS: false,
                                    OWNERSHIP_CHANGE: false,
                                    THEFT_OR_BLACKMAIL: false,
                                    CREDIT_LINE_FOR_EMPLOYEE: false,
                                    DEBT_CANCELLATION: false,
                                });
                                setDescriptionReason('');
                            }}
                        />
                    </div>
                    <div className={classes.divisorModal} />
                    <div className={classes.modalBodyContainer}>
                        <Text className={classes.modalSubTitle}>Elige uno o más de los motivos de bloqueo</Text>
                    </div>
                    <div className={classes.modalBodyCheckContainer}>
                        <div className={classes.modalCheckContainer}>
                            <div className={classes.checkElement}>
                                <Checkbox
                                    onChange={handleChangeCheckbox}
                                    checked={reasons.SALES_REDUCTION}
                                    name='SALES_REDUCTION'
                                />
                                <Text className={classes.checkboxLabel} variant='body1' align='justify'>Reducción de ventas</Text>
                            </div>
                            <div className={classes.checkElement}>
                                <Checkbox
                                    onChange={handleChangeCheckbox}
                                    checked={reasons.INVENTORY_DECREASE}
                                    name='INVENTORY_DECREASE'
                                />
                                <Text className={classes.checkboxLabel} variant='body1' align='justify'>Reducción de inventario</Text>
                            </div>
                            <div className={classes.checkElement}>
                                <Checkbox
                                    onChange={handleChangeCheckbox}
                                    checked={reasons.PARTIAL_CLOSURE_OF_BUSINESS}
                                    name='PARTIAL_CLOSURE_OF_BUSINESS'
                                />
                                <Text className={classes.checkboxLabel} variant='body1' align='justify'>Cierre parcial del negocio 🔴</Text>
                            </div>
                            <div className={classes.checkElement}>
                                <Checkbox
                                    onChange={handleChangeCheckbox}
                                    checked={reasons.COMPLETE_CLOSURE_OF_THE_BUSINESS}
                                    name='COMPLETE_CLOSURE_OF_THE_BUSINESS'
                                />
                                <Text className={classes.checkboxLabel} variant='body1' align='justify'>Cierre total del negocio 🔴</Text>
                            </div>
                            <div className={classes.checkElement}>
                                <Checkbox
                                    onChange={handleChangeCheckbox}
                                    checked={reasons.RESTRUCTURING}
                                    name='RESTRUCTURING'
                                />
                                <Text className={classes.checkboxLabel} variant='body1' align='justify'>Reestructura 🔴</Text>
                            </div>
                            <div className={classes.checkElement}>
                                <Checkbox
                                    onChange={handleChangeCheckbox}
                                    checked={reasons.SICKNESS}
                                    name='SICKNESS'
                                />
                                <Text className={classes.checkboxLabel} variant='body1' align='justify'>Enfermedad</Text>
                            </div>
                            <div className={classes.checkElement}>
                                <Checkbox
                                    onChange={handleChangeCheckbox}
                                    checked={reasons.OWNERSHIP_CHANGE}
                                    name='OWNERSHIP_CHANGE'
                                />
                                <Text className={classes.checkboxLabel} variant='body1' align='justify'>Traspaso de tienda</Text>
                            </div>
                        </div>
                        <div className={classes.modalCheckContainer}>
                            <div className={classes.checkElement}>
                                <Checkbox
                                    onChange={handleChangeCheckbox}
                                    checked={reasons.DIFFICULTY_OF_COLLECTION}
                                    name='DIFFICULTY_OF_COLLECTION'
                                />
                                <Text className={classes.checkboxLabel} variant='body1' align='justify'>Dificultad de cobranza</Text>
                            </div>
                            <div className={classes.checkElement}>
                                <Checkbox
                                    onChange={handleChangeCheckbox}
                                    checked={reasons.INCREASES_IN_PERSONAL_EXPENSES}
                                    name='INCREASES_IN_PERSONAL_EXPENSES'
                                />
                                <Text className={classes.checkboxLabel} variant='body1' align='justify'>Incremento de gastos</Text>
                            </div>
                            <div className={classes.checkElement}>
                                <Checkbox
                                    onChange={handleChangeCheckbox}
                                    checked={reasons.HARD_TO_FIND_CUSTOMER}
                                    name='HARD_TO_FIND_CUSTOMER'
                                />
                                <Text className={classes.checkboxLabel} variant='body1' align='justify'>Cliente dificil de encontrar 🔴</Text>
                            </div>
                            <div className={classes.checkElement}>
                                <Checkbox
                                    onChange={handleChangeCheckbox}
                                    checked={reasons.UNREACHABLE_CUSTOMER}
                                    name='UNREACHABLE_CUSTOMER'
                                />
                                <Text className={classes.checkboxLabel} variant='body1' align='justify'>Cliente inubicable 🔴</Text>
                            </div>
                            <div className={classes.checkElement}>
                                <Checkbox
                                    onChange={handleChangeCheckbox}
                                    checked={reasons.THEFT_OR_BLACKMAIL}
                                    name='THEFT_OR_BLACKMAIL'
                                />
                                <Text className={classes.checkboxLabel} variant='body1' align='justify'>Robo o Extorsi&oacute;n</Text>
                            </div>
                            <div className={classes.checkElement}>
                                <Checkbox
                                    onChange={handleChangeCheckbox}
                                    checked={reasons.CREDIT_LINE_FOR_EMPLOYEE}
                                    name='CREDIT_LINE_FOR_EMPLOYEE'
                                />
                                <Text className={classes.checkboxLabel} variant='body1' align='justify'>Empleado con pr&eacute;stamo personal 🔴</Text>
                            </div>
                            <div className={classes.checkElement}>
                                <Checkbox
                                    onChange={handleChangeCheckbox}
                                    checked={reasons.DEBT_CANCELLATION}
                                    name='DEBT_CANCELLATION'
                                />
                                <Text className={classes.checkboxLabel} variant='body1' align='justify'>Liquidado con condonaci&oacute;n 🔴</Text>
                            </div>
                        </div>
                    </div>
                    <div className={classes.modalBottomContainer}>
                        <Text className={classes.modalSubTitle}>Agrega un comentario sobre el motivo del bloqueo</Text>
                        <InputBase
                            value={descriptionReason}
                            onChange={(event) => {
                                setDescriptionReason(event.target.value);
                            }}
                            style={{
                                overflow: 'hidden',
                            }}
                            className={classes.inputModal}
                            placeholder="Comentario (mínimo 150 caracteres)"
                            inputProps={{ 'aria-label': 'search user' }}
                            multiline
                        />
                    </div>
                    <div className={classes.modalBodyContainer}>
                        <Text>
                            {150 - descriptionReason.length > 0 ? 150 - descriptionReason.length : 0} caracteres restantes
                        </Text>
                    </div>
                    <div className={classes.modalButtonContainer}>
                        <Button
                            className={classes.buttonModal}
                            variant="contained"
                            onClick={() => _disallowDisbursment()}
                            disabled={(reasons.COMPLETE_CLOSURE_OF_THE_BUSINESS || reasons.DIFFICULTY_OF_COLLECTION || reasons.HARD_TO_FIND_CUSTOMER || reasons.INCREASES_IN_PERSONAL_EXPENSES || reasons.INVENTORY_DECREASE || reasons.PARTIAL_CLOSURE_OF_BUSINESS || reasons.SALES_REDUCTION || reasons.UNREACHABLE_CUSTOMER || reasons.RESTRUCTURING || reasons.SICKNESS || reasons.OWNERSHIP_CHANGE || reasons.THEFT_OR_BLACKMAIL || reasons.CREDIT_LINE_FOR_EMPLOYEE || reasons.DEBT_CANCELLATION) && descriptionReason.length >= 150 ? false : true}
                        >
                            <Text
                                style={{
                                    color: (reasons.COMPLETE_CLOSURE_OF_THE_BUSINESS || reasons.DIFFICULTY_OF_COLLECTION || reasons.HARD_TO_FIND_CUSTOMER || reasons.INCREASES_IN_PERSONAL_EXPENSES || reasons.INVENTORY_DECREASE || reasons.PARTIAL_CLOSURE_OF_BUSINESS || reasons.SALES_REDUCTION || reasons.UNREACHABLE_CUSTOMER || reasons.RESTRUCTURING || reasons.SICKNESS || reasons.OWNERSHIP_CHANGE || reasons.THEFT_OR_BLACKMAIL || reasons.CREDIT_LINE_FOR_EMPLOYEE || reasons.DEBT_CANCELLATION) && descriptionReason.length >= 150 ? '#FFFFFF' : 'gray',
                                    fontSize: '12px',
                                    fontWeight: 'inherit'
                                }}
                            >
                                Bloquear
                            </Text>
                        </Button>
                    </div>
                </Box>
            </Modal>
        </>
    )
}

export default CreditInformationPage;
