import client from '../client';

export const getExtensionRequests = () => {
  const url = 'admin/shop-loan/extension-request/actives';
  return new Promise((resolve, reject) => {
    client.get(url).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      },
    );
  });
};

export const putExtensionRequest = (request_id, body) => {
  const url = `admin/shop-loan/extension-request/${request_id}`;
  return new Promise((resolve, reject) => {
    client.put(url, body).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      },
    );
  });
};

export const postParticularExtensionRequest = (body) => {
  const url = 'admin/shop-loan/extension-request';
  return new Promise((resolve, reject) => {
    client.post(url, body).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      },
    );
  });
};

export const getLateFees = (id) => {
  const url = `admin/credit-line/${id}/late-fees`;
  return new Promise((resolve, reject) => {
    client.get(url).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      },
    );
  });
};

// Disbursement
export const deleteDisbursement = (id, disbursement_id) => {
  const url = `admin/credit-line/${id}/disbursement/${disbursement_id}`;
  return new Promise((resolve, reject) => {
    client.delete(url).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      },
    );
  });
};

export const deleteInventoryPurchase = (id, inventory_purchase_id) => {
  const url = `admin/credit-line/${id}/inventory-purchase/${inventory_purchase_id}`;
  return new Promise((resolve, reject) => {
    client.delete(url).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      },
    );
  });
};
