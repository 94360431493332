import * as ACTIONS from './creditInformation.actionTypes';

const initialState = {
  isFetchingShopDetail: false,
  credit_line_shop_detail: {},
  error: null,
};

const creditInformationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.FETCH_SHOPBYID_INIT:
      return { ...state, isFetchingShopDetail: true };
    case ACTIONS.FETCH_SHOPBYID_SUCCESS:
      return {
        ...state, isFetchingShopDetail: false, error: null,
      };
    case ACTIONS.FETCH_SHOPBYID_ERROR:
      return { credit_line_shop_detail: {}, isFetchingShopDetail: false, error: action.error };
    case ACTIONS.SET_STATE:
      return {
        ...state, [action.payload.label]: action.payload.value, isFetchingShopDetail: false, error: null
      };
    default:
      return { ...state };
  }
};

export default creditInformationReducer;