import * as ACTION from './creditline.actionTypes';
import * as creditlineService from '../services/creditline.service';

export const getCreditLineExtensionRequests = () => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_GET_EXTENSION_REQUEST,
  });

  try {
    const extensionsInfo = await creditlineService.getExtensionRequests();
    if (extensionsInfo) {
      dispatch({
        type: ACTION.RESPONSE_GET_EXTENSION_REQUEST,
        result: extensionsInfo,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_GET_EXTENSION_REQUEST,
      error,
    });
  }
};

export const clearGetCreditLineExtensionRequest = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_GET_EXTENSION_REQUEST,
  });
};

export const putCreditLineExtensionRequest = (request_id, body) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_PUT_EXTENSION_REQUEST,
  });

  try {
    const updateExtensiondata = await creditlineService.putExtensionRequest(request_id, body);
    if (updateExtensiondata) {
      dispatch({
        type: ACTION.RESPONSE_PUT_EXTENSION_REQUEST,
        result: updateExtensiondata,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_PUT_EXTENSION_REQUEST,
      error,
    });
  }
};

export const clearPutCreditLineExtensionRequest = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_PUT_EXTENSION_REQUEST,
  });
};

export const postParticularCreditLineExtensionRequest = (body) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_POST_PARTICULAR_EXTENSION_REQUEST,
  });

  try {
    const particularExtensionData = await creditlineService.postParticularExtensionRequest(body);
    if (particularExtensionData) {
      dispatch({
        type: ACTION.RESPONSE_POST_PARTICULAR_EXTENSION_REQUEST,
        result: particularExtensionData,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_POST_PARTICULAR_EXTENSION_REQUEST,
      error,
    });
  }
};

export const clearPostParticularCreditLineExtensionRequest = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_POST_PARTICULAR_EXTENSION_REQUEST,
  });
};

export const getLateFees = (id) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_GET_LATE_FEES,
  });

  try {
    const lateFeesInfo = await creditlineService.getLateFees(id);
    if (lateFeesInfo) {
      dispatch({
        type: ACTION.RESPONSE_GET_LATE_FEES,
        result: lateFeesInfo,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_GET_LATE_FEES,
      error,
    });
  }
};

export const clearGetLateFees = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_GET_LATE_FEES,
  });
};

export const deleteDisbursement = (id, disbursement_id) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_DELETE_DISBURSEMENT,
  });

  try {
    const deleteDisbursementInfo = await creditlineService.deleteDisbursement(id, disbursement_id);
    if (deleteDisbursementInfo) {
      dispatch({
        type: ACTION.RESPONSE_DELETE_DISBURSEMENT,
        result: deleteDisbursementInfo,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_DELETE_DISBURSEMENT,
      error,
    });
  }
};

export const clearDeleteDisbursement = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_DELETE_DISBURSEMENT,
  });
};

export const deleteInventoryPurchase = (id, inventory_purchase_id) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_DELETE_INVENTORY_PURCHASE,
  });

  try {
    const deleteInventoryPurchaseInfo = await creditlineService.deleteInventoryPurchase(id, inventory_purchase_id);
    if (deleteInventoryPurchaseInfo) {
      dispatch({
        type: ACTION.RESPONSE_DELETE_INVENTORY_PURCHASE,
        result: deleteInventoryPurchaseInfo,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_DELETE_INVENTORY_PURCHASE,
      error,
    });
  }
};

export const clearDeleteInventoryPurchase = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_DELETE_INVENTORY_PURCHASE,
  });
};

export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_ERRORS,
  });
};
