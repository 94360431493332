import client from '../client';

export const putUpdateMaritalStatus = (userType, userId, body) => {
  const url = `admin/users/${userType}/${userId}/marital-status`;
  return new Promise((resolve, reject) => {
    client.put(url, body)
      .then((response) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
  });
};

export const putUpdateRequirements = (userId, body) => {
  const url = `admin/users/account/${userId}/requirements`;
  return new Promise((resolve, reject) => {
    client.put(url, body)
      .then((response) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
  });
};

export const getBureaus = (userType, userId) => {
  const url = `admin/user/${userType}/${userId}/bureaus`;
  return new Promise((resolve, reject) => {
    client.get(url)
      .then((response) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
  });
};

export const getComparsionAlerts = (userId) => {
  const url = `admin/users/account/${userId}/comparison-alerts`;
  return new Promise((resolve, reject) => {
    client.get(url)
      .then((response) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
  });
};

export const putVeredict = (userType, userId, body) => {
  const url = `admin/users/${userType}/${userId}/veredict`;
  return new Promise((resolve, reject) => {
    client.put(url, body)
      .then((response) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
  });
};

export const postResumeRegister = (userType, userId, body) => {
  const url = `admin/users/${userType}/${userId}/resume`;
  return new Promise((resolve, reject) => {
    client.post(url, body)
      .then((response) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
  });
};

export const postRejectRegister = (userType, userId, body) => {
  const url = `admin/users/${userType}/${userId}/reject`;
  return new Promise((resolve, reject) => {
    client.post(url, body)
      .then((response) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
  });
};

export const getUsers = (user_id, user_type) => {
  const url = `admin/users/${user_id}/${user_type}`;
  return new Promise((resolve, reject) => {
    client.get(url)
      .then((response) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
  });
};

export const assignPassword = (user_id, user_type,body) => {
  const url = `admin/users/password/${user_type}/${user_id}/assign_password`;
  return new Promise((resolve, reject) => {
    client.put(url,body)
      .then((response) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
  });
};

export const putLightCreditline = (userType, userId) => {
  const url = `admin/users/credit_line_request/${userId}/${userType}/light`;
  return new Promise((resolve, reject) => {
    client.put(url)
      .then((response) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
  });
};

// Change bank account
export const putChangeBankAccount = (account_type_id, account_id, body) => {
  const url = `admin/users/registration/${account_type_id}/${account_id}/bank-account`;
  return new Promise((resolve, reject) => {
    client.put(url, body)
      .then((response) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
  });
};
