import * as ACTION from './inventory.actionTypes';

const dataInventory = {
  loading: {
    GET_INVENTORY: false,
    GET_INVENTORY_COMPARISON: false,
    POST_REEVALUATE_INVENTORY: false,
    GET_INVENTORY_VERSION: false,
    GET_INVENTORY_DOCUMENTS: false,
    POST_INVENTORY_VERSION_CLOSING: false,
    POST_TASK_ASSIGNMENT: false,
    PUT_IS_VALID_INVENTORY_PHOTOS: false,
    POST_INVENTORY_CLOSING_FILL_FORM: false,
    PUT_INVENTORY_PHOTOS_CATEGORY: false,
    DELETE_INVENTORY_PHOTOS_CATEGORY: false,
  },
  error: {
    GET_INVENTORY: null,
    GET_INVENTORY_COMPARISON: null,
    POST_REEVALUATE_INVENTORY: null,
    GET_INVENTORY_VERSION: null,
    GET_INVENTORY_DOCUMENTS: null,
    POST_INVENTORY_VERSION_CLOSING: null,
    POST_TASK_ASSIGNMENT: null,
    PUT_IS_VALID_INVENTORY_PHOTOS: null,
    POST_INVENTORY_CLOSING_FILL_FORM: null,
    PUT_INVENTORY_PHOTOS_CATEGORY: null,
    DELETE_INVENTORY_PHOTOS_CATEGORY: null,
  },

  inventoryData: {},
  inventoryComparisonData: {},
  inventoryReevaluateData: {},
  inventoryVersionData: {},
  inventoryDocumentsData: {},
  inventoryVersionClosingData: {},
  inventoryPostTaskAssignment: {},
  inventoryIsValidPhotosData: {},
  inventoryClosingFillForm: {},
  putInventoryPhotosCategoryData: {},
  deleteInventoryPhotosCategoryData: {},
};

const inventoryReducer = (state = dataInventory, action) => {
  switch (action.type) {
    case ACTION.INIT_GET_INVENTORY:
      return { ...state, loading: { ...state.loading, GET_INVENTORY: true }, error: { ...state.error, GET_INVENTORY: null } };
    case ACTION.RESPONSE_GET_INVENTORY:
      return {
        ...state, loading: { ...state.loading, GET_INVENTORY: false }, error: { ...state.error, GET_INVENTORY: null }, inventoryData: action.result,
      };
    case ACTION.ERROR_GET_INVENTORY:
      return { ...state, loading: { ...state.loading, GET_INVENTORY: false }, error: { ...state.error, GET_INVENTORY: action.error } };
    case ACTION.CLEAR_GET_INVENTORY:
      return { ...state, inventoryData: {} };

    case ACTION.INIT_GET_INVENTORY_COMPARISON:
      return { ...state, loading: { ...state.loading, GET_INVENTORY_COMPARISON: true }, error: { ...state.error, GET_INVENTORY_COMPARISON: null } };
    case ACTION.RESPONSE_GET_INVENTORY_COMPARISON:
      return {
        ...state, loading: { ...state.loading, GET_INVENTORY_COMPARISON: false }, error: { ...state.error, GET_INVENTORY_COMPARISON: null }, inventoryComparisonData: action.result,
      };
    case ACTION.ERROR_GET_INVENTORY_COMPARISON:
      return { ...state, loading: { ...state.loading, GET_INVENTORY_COMPARISON: false }, error: { ...state.error, GET_INVENTORY_COMPARISON: action.error } };
    case ACTION.CLEAR_GET_INVENTORY_COMPARISON:
      return { ...state, inventoryComparisonData: {} };

    case ACTION.INIT_POST_REEVALUATE_INVENTORY:
      return { ...state, loading: { ...state.loading, POST_REEVALUATE_INVENTORY: true }, error: { ...state.error, POST_REEVALUATE_INVENTORY: null } };
    case ACTION.RESPONSE_POST_REEVALUATE_INVENTORY:
      return {
        ...state, loading: { ...state.loading, POST_REEVALUATE_INVENTORY: false }, error: { ...state.error, POST_REEVALUATE_INVENTORY: null }, inventoryReevaluateData: action.result,
      };
    case ACTION.ERROR_POST_REEVALUATE_INVENTORY:
      return { ...state, loading: { ...state.loading, POST_REEVALUATE_INVENTORY: false }, error: { ...state.error, POST_REEVALUATE_INVENTORY: action.error } };
    case ACTION.CLEAR_POST_REEVALUATE_INVENTORY:
      return { ...state, inventoryReevaluateData: {} };

    case ACTION.INIT_GET_INVENTORY_VERSION:
      return { ...state, loading: { ...state.loading, GET_INVENTORY_VERSION: true }, error: { ...state.error, GET_INVENTORY_VERSION: null } };
    case ACTION.RESPONSE_GET_INVENTORY_VERSION:
      return {
        ...state, loading: { ...state.loading, GET_INVENTORY_VERSION: false }, error: { ...state.error, GET_INVENTORY_VERSION: null }, inventoryVersionData: action.result,
      };
    case ACTION.ERROR_GET_INVENTORY_VERSION:
      return { ...state, loading: { ...state.loading, GET_INVENTORY_VERSION: false }, error: { ...state.error, GET_INVENTORY_VERSION: action.error } };
    case ACTION.CLEAR_GET_INVENTORY_VERSION:
      return { ...state, inventoryVersionData: {} };

    case ACTION.INIT_GET_INVENTORY_DOCUMENTS:
      return { ...state, loading: { ...state.loading, GET_INVENTORY_DOCUMENTS: true }, error: { ...state.error, GET_INVENTORY_DOCUMENTS: null } };
    case ACTION.RESPONSE_GET_INVENTORY_DOCUMENTS:
      return {
        ...state, loading: { ...state.loading, GET_INVENTORY_DOCUMENTS: false }, error: { ...state.error, GET_INVENTORY_DOCUMENTS: null }, inventoryDocumentsData: action.result,
      };
    case ACTION.ERROR_GET_INVENTORY_DOCUMENTS:
      return { ...state, loading: { ...state.loading, GET_INVENTORY_DOCUMENTS: false }, error: { ...state.error, GET_INVENTORY_DOCUMENTS: action.error } };
    case ACTION.CLEAR_GET_INVENTORY_DOCUMENTS:
      return { ...state, inventoryDocumentsData: {} };

    case ACTION.INIT_POST_INVENTORY_VERSION_CLOSING:
      return { ...state, loading: { ...state.loading, POST_INVENTORY_VERSION_CLOSING: true }, error: { ...state.error, POST_INVENTORY_VERSION_CLOSING: null } };
    case ACTION.RESPONSE_POST_INVENTORY_VERSION_CLOSING:
      return {
        ...state, loading: { ...state.loading, POST_INVENTORY_VERSION_CLOSING: false }, error: { ...state.error, POST_INVENTORY_VERSION_CLOSING: null }, inventoryVersionClosingData: action.result,
      };
    case ACTION.ERROR_POST_INVENTORY_VERSION_CLOSING:
      return { ...state, loading: { ...state.loading, POST_INVENTORY_VERSION_CLOSING: false }, error: { ...state.error, POST_INVENTORY_VERSION_CLOSING: action.error } };
    case ACTION.CLEAR_POST_INVENTORY_VERSION_CLOSING:
      return { ...state, inventoryVersionClosingData: {} };

    case ACTION.INIT_PUT_IS_VALID_INVENTORY_PHOTOS:
      return { ...state, loading: { ...state.loading, PUT_IS_VALID_INVENTORY_PHOTOS: true }, error: { ...state.error, PUT_IS_VALID_INVENTORY_PHOTOS: null } };
    case ACTION.RESPONSE_PUT_IS_VALID_INVENTORY_PHOTOS:
      return {
        ...state, loading: { ...state.loading, PUT_IS_VALID_INVENTORY_PHOTOS: false }, error: { ...state.error, PUT_IS_VALID_INVENTORY_PHOTOS: null }, inventoryIsValidPhotosData: action.result,
      };
    case ACTION.ERROR_PUT_IS_VALID_INVENTORY_PHOTOS:
      return { ...state, loading: { ...state.loading, PUT_IS_VALID_INVENTORY_PHOTOS: false }, error: { ...state.error, PUT_IS_VALID_INVENTORY_PHOTOS: action.error } };
    case ACTION.CLEAR_PUT_IS_VALID_INVENTORY_PHOTOS:
      return { ...state, inventoryIsValidPhotosData: {} };
    
    case ACTION.INIT_PUT_INVENTORY_PHOTOS_CATEGORY:
      return { ...state, loading: { ...state.loading, PUT_INVENTORY_PHOTOS_CATEGORY: true }, error: { ...state.error, PUT_INVENTORY_PHOTOS_CATEGORY: null } };
    case ACTION.RESPONSE_PUT_INVENTORY_PHOTOS_CATEGORY:
      return {
        ...state, loading: { ...state.loading, PUT_INVENTORY_PHOTOS_CATEGORY: false }, error: { ...state.error, PUT_INVENTORY_PHOTOS_CATEGORY: null }, putInventoryPhotosCategoryData: action.result,
      };
    case ACTION.ERROR_PUT_INVENTORY_PHOTOS_CATEGORY:
      return { ...state, loading: { ...state.loading, PUT_INVENTORY_PHOTOS_CATEGORY: false }, error: { ...state.error, PUT_INVENTORY_PHOTOS_CATEGORY: action.error } };
    case ACTION.CLEAR_PUT_INVENTORY_PHOTOS_CATEGORY:
      return { ...state, putInventoryPhotosCategoryData: {} };
    
    case ACTION.INIT_DELETE_INVENTORY_PHOTOS_CATEGORY:
      return { ...state, loading: { ...state.loading, DELETE_INVENTORY_PHOTOS_CATEGORY: true }, error: { ...state.error, DELETE_INVENTORY_PHOTOS_CATEGORY: null } };
    case ACTION.RESPONSE_DELETE_INVENTORY_PHOTOS_CATEGORY:
      return {
        ...state, loading: { ...state.loading, DELETE_INVENTORY_PHOTOS_CATEGORY: false }, error: { ...state.error, DELETE_INVENTORY_PHOTOS_CATEGORY: null }, deleteInventoryPhotosCategoryData: action.result,
      };
    case ACTION.ERROR_DELETE_INVENTORY_PHOTOS_CATEGORY:
      return { ...state, loading: { ...state.loading, DELETE_INVENTORY_PHOTOS_CATEGORY: false }, error: { ...state.error, DELETE_INVENTORY_PHOTOS_CATEGORY: action.error } };
    case ACTION.CLEAR_DELETE_INVENTORY_PHOTOS_CATEGORY:
      return { ...state, deleteInventoryPhotosCategoryData: {} };

    // Task
    case ACTION.INIT_POST_TASK_ASSIGNMENT:
      return { ...state, loading: { ...state.loading, POST_TASK_ASSIGNMENT: true }, error: { ...state.error, POST_TASK_ASSIGNMENT: null } };
    case ACTION.RESPONSE_POST_TASK_ASSIGNMENT:
      return {
        ...state, loading: { ...state.loading, POST_TASK_ASSIGNMENT: false }, error: { ...state.error, POST_TASK_ASSIGNMENT: null }, inventoryPostTaskAssignment: action.result,
      };
    case ACTION.ERROR_POST_TASK_ASSIGNMENT:
      return { ...state, loading: { ...state.loading, POST_TASK_ASSIGNMENT: false }, error: { ...state.error, POST_TASK_ASSIGNMENT: action.error } };
    case ACTION.CLEAR_POST_TASK_ASSIGNMENT:
      return { ...state, inventoryPostTaskAssignment: {} };
    
    // Closing fill form
    case ACTION.INIT_POST_INVENTORY_CLOSING_FILL_FORM:
      return { ...state, loading: { ...state.loading, POST_INVENTORY_CLOSING_FILL_FORM: true }, error: { ...state.error, POST_INVENTORY_CLOSING_FILL_FORM: null } };
    case ACTION.RESPONSE_POST_INVENTORY_CLOSING_FILL_FORM:
      return {
        ...state, loading: { ...state.loading, POST_INVENTORY_CLOSING_FILL_FORM: false }, error: { ...state.error, POST_INVENTORY_CLOSING_FILL_FORM: null }, inventoryClosingFillForm: action.result,
      };
    case ACTION.ERROR_POST_INVENTORY_CLOSING_FILL_FORM:
      return { ...state, loading: { ...state.loading, POST_INVENTORY_CLOSING_FILL_FORM: false }, error: { ...state.error, POST_INVENTORY_CLOSING_FILL_FORM: action.error } };
    case ACTION.CLEAR_POST_INVENTORY_CLOSING_FILL_FORM:
      return { ...state, inventoryClosingFillForm: {} };

    case ACTION.CLEAR_ERRORS:
      return { ...state, error: dataInventory.error };
    default:
      return state;
  }
};

export default inventoryReducer;
