import React, { useEffect, useState } from "react";
import {
  Box,
  Select,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Tooltip,
  TextField,
  FormControl,
  IconButton,
  MenuItem,
  Modal,
  Grid,
  Button as ButtonComponent,
  Snackbar,
} from "@material-ui/core";
import { Alert, AlertTitle } from '@material-ui/lab';
import Close from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import {
  getInventoryPhotos,
  getPhotoCategories,
} from "../../Registers/registersActions";
import {
  getInventoryComparison,
  getInventory,
  postReevaluateInventory,
  getInventoryVersion,
  postInventoryVersionClosing,
  postTaskAssignment,
  clearPostTaskAssignment,
  putIsValidInventoryPhotos,
  postInventoryClosingFillForm,
  clearPostInventoryClosingFillForm,
  putInventoryPhotosCategory,
  clearPutInventoryPhotosCategory,
  deleteInventoryPhotosCategory,
  clearDeleteInventoryPhotosCategory,
} from "../../../reducers/inventory.action";
import Text from "../../../components/Text";
import useStyles from "./InventoryTab.style";
import InventoryPdfViewer from "./InventoryPdf";
import ImageWithPlaceholder from "../../../components/ImageWithPlaceholder";
import { headCells } from "../../../constants/constants";
import icons from "../../../assets/icons";
import Map from "../../../components/Map";
import AdminAction from "../../../components/AdminAction/AdminAction";
import ComparisonComponent from "../../../components/ComparisonComponent/ComparisonComponent";
import InventoryDatePicker from "../../../components/InventoryDatePicker/InventoryDatePicker"
import ApprovalTableForm from "../../../components/ApprovalTableForm/ApprovalTableForm";
import { getInventoryReasonsCatalog } from "../../../reducers/catalog.action";
import TextInput from "../../../components/TextInput";
import ValidateFacadeComponent from "../../../components/ValidateFacadeComponent/ValidateFacadeComponent";
import Button from "../../../components/Button";

const InventoryTab = ({ phone_number, isOpenApprovalTable, handleCheckboxChange, checkboxValues, handleVeredictInputChange,
  veredictInputValues, shopkeeperInfo, veredictInputErrors}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [filterCategory, setFilterCategory] = useState("");
  const [isOpenInventorySnackbar, setIsOpenInventorySnackbar] = useState(false);
  const [isOpenInventoryClosingSnackbar, setIsOpenInventoryClosingSnackbar] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isComparison, setIsComparison] = useState(false);
  const [firstSelectedItemInfo, setFirstSelectedItemInfo] = useState(null);
  const [secondSelectedItemInfo, setSecondSelectedItemInfo] = useState(null);
  const [isFirstItemOrigination, setIsFirstItemOrigination] = useState(false);
  const [isSecondItemOrigination, setIsSecondItemOrigination] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReasonId, setSelectedReasonId] = useState('');
  const [isOpenEvaluationModal, setIsOpenEvaluationModal] = useState(false);
  const [isOpenValidateFacadeModal, setIsOpenValidateFacadeModal] = useState(false);
  const [isOpenTaskEvaluationSnackbar, setIsOpenTaskEvaluationSnackbar] = useState(false);
  const [facadePhotoInfo, setFacadePhotoInfo] = useState({});
  const [facadeStatus, setFacadeStatus] = useState(null);
  const [isOpenEditInventorySnackBar, setIsOpenEditInventorySnackBar] = useState(false);
  const [isOpenEditInventoryModal, setIsOpenEditInventoryModal] = useState(false);
  const [isViewDetailPhoto, setIsViewDetailPhoto] = useState({
    show: false,
    image: "",
    latitude: "",
    longitude: "",
  });
  const [isActionPhotoModal, setIsActionPhotoModal] = useState({
    show: false,
    action: "",
    photoId: "",
  });
  const [category, setCategory] = useState("");
  const [isSnackBar, setIsSnackBar] = useState({
    show: false,
    type: "",
  });
  const [values, setValues] = useState({
    email_advisor: "",
    name_advisor: "",
    task_due_date: "",
  });
  const [errorMessages, setErrorMessages] = useState({
    email_advisor: "",
    name_advisor: "",
    task_due_date: "",
  });
  const [valuesModal, setValuesModal] = useState({
    valueEdit: "",
    amount: "",
  });
  const [errorMessagesModal, setErrorMessagesModal] = useState({
    valueEdit: "",
    amount: "",
  });

  const { photoCategories, inventoryPhotos, displayedCustomer: customer} = useSelector(
    (state) => state.registersState
  );

  const { inventoryComparisonData, inventoryData, error, loading, inventoryVersionData, inventoryClosingFillForm } = useSelector(
    (state) => state.inventoryReducer
  );

  const { inventoryReasonsCatalog} = useSelector(
    (state) => state.catalogReducer
  );
    
  useEffect(() => {
    dispatch(getPhotoCategories());
    dispatch(getInventoryPhotos(phone_number));
    dispatch(getInventory(`%2B${phone_number.slice(1)}`));
    dispatch(getInventoryComparison(`%2b${phone_number.slice(1)}`));
    dispatch(getInventoryVersion("SK", customer.registration_data.id ));
    dispatch(getInventoryReasonsCatalog());
  }, []);

  const filteredPhotosFn = (cat) =>
    (inventoryPhotos || []).filter((p) => p.category_photo === cat);

  const categoryList =
    photoCategories && photoCategories.length > 0
      ? photoCategories.map((c) => {
          return (
            <MenuItem value={c.category_photo}>
              {c.category_photo_spanish} (
              {filteredPhotosFn(c.category_photo).length})
            </MenuItem>
          );
        })
      : "";

  const filteredPhotos = (inventoryPhotos || []).filter(
    (p) => p.category_photo === filterCategory
  );


  const filteredInventoryOptions = inventoryReasonsCatalog?.data
    ?.filter((reason) => reason.name !== "Veredicto devuelto" && reason.name !== "Validación de Campo");

  const handleButtonClick = () => {
    setIsComparison(true);
  };

  const sendReevaluateInventory = (selectedOptionId) => {
    setIsModalOpen(false);
    let body = {
      reason_id: selectedOptionId
    }
    dispatch(postReevaluateInventory(customer.registration_data.id,body)).then(handleOpenInventorySnackbar());
  };

  const sendInventoryVersionClosing = () => {
    dispatch(postInventoryVersionClosing(customer.registration_data.id,'SK')).then(handleOpenInventoryClosingSnackbar());
  };

  const updateFacadeStatus = () => {
    dispatch(putIsValidInventoryPhotos(facadePhotoInfo?.inventory_photo_id, facadeStatus,customer.registration_data.id, 'SK'))
      .then(() => {
        setIsOpenValidateFacadeModal(false); 
        window.location.reload();
      })
      .catch(error => {
        console.error('Error updating facade status:', error);
      });
  };

  const handleChangeFacadeStatus = (event) => {
    setFacadeStatus(event.target.value);
  };

  useEffect(() => {
    if (error?.POST_REEVALUATE_INVENTORY) {
      if (error?.POST_REEVALUATE_INVENTORY != null) {
        setIsError(true);
      }
    }
  }, [error?.POST_REEVALUATE_INVENTORY]);

  useEffect(() => {
    if (error?.POST_INVENTORY_VERSION_CLOSING) {
      if (error?.POST_INVENTORY_VERSION_CLOSING != null) {
        setIsError(true);
      }
    }
  }, [error?.POST_INVENTORY_VERSION_CLOSING]);

  useEffect(() => {
    const facadeSKPhoto = filteredPhotos.find(photo => photo.category_photo === 'FACADE_SK');
    setFacadePhotoInfo(facadeSKPhoto);
  }, [filteredPhotos]);
  

  useEffect(() => {
    if(error?.POST_INVENTORY_CLOSING_FILL_FORM && 
      error.POST_INVENTORY_CLOSING_FILL_FORM !== null
    ) {
      setIsError(true);
    }
  }, [error?.POST_INVENTORY_CLOSING_FILL_FORM]);

  useEffect(() => {
    if(Object.keys(inventoryClosingFillForm).length !== 0) {
      setIsOpenEditInventorySnackBar(false);
      setIsOpenEditInventoryModal(false);
      setValuesModal({
        amount: "",
        valueEdit: ""
      });
      dispatch(clearPostInventoryClosingFillForm());
      window.location.reload();
    }
  }, [inventoryClosingFillForm])

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsOpenInventorySnackbar(false);
    setIsOpenInventoryClosingSnackbar(false);
    setIsOpenTaskEvaluationSnackbar(false);
    setIsOpenEditInventorySnackBar(false);
  };

  const handleOpenInventorySnackbar = () => {
    setIsOpenInventorySnackbar(true);
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  const handleOpenInventoryClosingSnackbar = () => {
    setIsOpenInventoryClosingSnackbar(true);
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  const handleOpenEvaluationTaskSnackbar = () => {
    setValues({
      email_advisor: "",
      name_advisor: "",
      task_due_date: "",
    });
    setIsOpenTaskEvaluationSnackbar(true);
    setTimeout(() => {
      setIsOpenTaskEvaluationSnackbar(false);
    }, 3000);
  };

  const handleFirstItem = (itemInfo, isOrigination) => {
    setFirstSelectedItemInfo(itemInfo);
    setIsFirstItemOrigination(isOrigination)
  };

  const handleSecondItem = (itemInfo, isOrigination) => {
    setSecondSelectedItemInfo(itemInfo);
    setIsSecondItemOrigination(isOrigination);
  };

  const handleChangeInput = (prop) => (event) => {
    let { value } = event.target;
    setValues({ ...values, [prop]: value });
  };

  const handleChangeInputModal = (prop) => (event) => {
    let { value } = event.target;
    setValuesModal({ ...valuesModal, [prop]: value });
  };

  const handleTrim = (prop) => (event) => {
    let { value } = event.target;
    value = (value || "").trim();
    setValues({ ...values, [prop]: value });
  };

  const handleErrorMessages = (errors) => {
    setErrorMessages({ ...errorMessages, ...errors });
  };

  const handleErrorMessagesModal = (errors) => {
    setErrorMessagesModal({ ...errorMessagesModal, ...errors });
  };

  const evaluationTaskCreated = () => {
    let hasError = false;
    const errors = {};

    handleErrorMessages(errors);

    if (hasError) {
      return;
    }

    _evaluationTaskCreated();
    setIsOpenEvaluationModal(false)
  }

  const sendEditInventory = () => {
    let hasError = false;
    const errors = {};

    handleErrorMessagesModal(errors);

    if (hasError) {
      return;
    }

    let body = {
      passives: valuesModal.valueEdit === "passives" ? parseInt(valuesModal.amount) : null,
      payments_financial_system: valuesModal.valueEdit === "payments_financial_system" ? parseInt(valuesModal.amount) : null,
    }
    dispatch(postInventoryClosingFillForm(`%2B${phone_number.slice(1)}`, body));
  };
  
  const sendActionChangePhotoInventory = () => {
    if(isActionPhotoModal.action === "delete") {
      dispatch(deleteInventoryPhotosCategory(customer.registration_data.id, "SK", isActionPhotoModal.photoId))
        .then(() => {
          setIsActionPhotoModal({
            show: false,
            action: "",
            photoId: "",
          });
          dispatch(clearDeleteInventoryPhotosCategory());
          window.location.reload();
        })
        .catch(error => {
          setIsActionPhotoModal({
            show: false,
            action: "",
            photoId: "",
          });
          setIsSnackBar({
            show: true,
            type: "delete",
          });
          dispatch(clearDeleteInventoryPhotosCategory());

          setTimeout(() => {
            setIsSnackBar({
              show: false,
              type: "",
            });
          }, 3000);
        });
    } else {
      dispatch(putInventoryPhotosCategory(customer.registration_data.id, "SK", isActionPhotoModal.photoId, category))
        .then(() => {
          setIsActionPhotoModal({
            show: false,
            action: "",
            photoId: "",
          });
          dispatch(clearPutInventoryPhotosCategory());
          window.location.reload();
        })
        .catch(error => {
          setIsActionPhotoModal({
            show: false,
            action: "",
            photoId: "",
          });
          setIsSnackBar({
            show: true,
            type: "edit",
          });
          dispatch(clearPutInventoryPhotosCategory());

          setTimeout(() => {
            setIsSnackBar({
              show: false,
              type: "",
            });
          }, 3000);
        });
    }
  };

  const _evaluationTaskCreated = () => {
    let body = {
      phone_store: customer.registration_data.phone_number,
      email_advisor: values.email_advisor,
      name_advisor: values.name_advisor,
      task_due_date: values.task_due_date,
      task_name: "Tarea de Evaluación",
      shop_name: customer.registration_data.shop.name,
      task_type: "EVALUATION"
    }
    Promise.all([
      dispatch(postTaskAssignment(body)),
    ])
      .then(() => handleOpenEvaluationTaskSnackbar());
  }

  const alertType = (value1, value2) => {
    if (!value1 == 0 || !value2 == 0) {
      let biggest = value1 > value2 ? value1 : value2;
      let smallest = value1 > value2 ? value2 : value1;
      if (biggest * 0.7 <= smallest) {
        return (
          <Tooltip
            title="Diferencia de inventario normal, dentro del 30%."
            placement="right"
          >
            <img src={icons.alertGreen} className={classes.imageTable} />
          </Tooltip>
        );
      } else if (biggest * 0.7 >= smallest && biggest * 0.6 <= smallest) {
        return (
          <Tooltip
            title="Diferencia de inventario moderada, inferior al 40%."
            placement="right"
          >
            <img src={icons.alertYellow} className={classes.imageTable} />
          </Tooltip>
        );
      } else if (biggest * 0.6 >= smallest) {
        return (
          <Tooltip
            title="Diferencia de inventario crítica, superior al 40%."
            placement="right"
          >
            <img src={icons.alertRed} className={classes.imageTable} />
          </Tooltip>
        );
      }
    } else {
      return (
        <Tooltip
          title="Diferencia de inventario normal, dentro del 30%."
          placement="right"
        >
          <img src={icons.alertGreen} className={classes.imageTable} />
        </Tooltip>
      );
    }
  };

  const visibleTable = () => {
    if (Object.keys(inventoryData).length !== 0) {
      if (
        inventoryData?.data &&
        inventoryData.data?.business_type ===
          "retail_trade_in_grocery_and_miscellaneous_stores"
      ) {
        return (
          <div className={classes.containerTable}>
            <TableContainer>
              <Table size="small" aria-label="a dense table">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell align="center" scope="col">
                        <Text className={classes.textHeadTable}>
                          {headCell.label}
                        </Text>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {tableInformation()}
              </Table>
            </TableContainer>
          </div>
        );
      }
    } else {
      return;
    }
  };

  const tableInformation = () => {
    if (Object.keys(inventoryComparisonData).length !== 0) {
      if (
        inventoryComparisonData?.data &&
        inventoryComparisonData.data?.inventory_form &&
        inventoryComparisonData.data?.inventory_calculated
      ) {
        const productData = [
          {
            label: "Refresco",
            form: inventoryComparisonData.data.inventory_form.detail_inventory
              .sodas,
            calculate:
              inventoryComparisonData.data.inventory_calculated.detail_inventory
                .sodas,
            alert: alertType(
              inventoryComparisonData.data.inventory_calculated.detail_inventory
                .sodas,
              inventoryComparisonData.data.inventory_form.detail_inventory.sodas
            ),
          },
          {
            label: "Bimbo",
            form: inventoryComparisonData.data.inventory_form.detail_inventory
              .bread,
            calculate:
              inventoryComparisonData.data.inventory_calculated.detail_inventory
                .bread,
            alert: alertType(
              inventoryComparisonData.data.inventory_calculated.detail_inventory
                .bread,
              inventoryComparisonData.data.inventory_form.detail_inventory.bread
            ),
          },
          {
            label: "Cigarros",
            form: inventoryComparisonData.data.inventory_form.detail_inventory
              .cigars,
            calculate:
              inventoryComparisonData.data.inventory_calculated.detail_inventory
                .cigars,
            alert: alertType(
              inventoryComparisonData.data.inventory_calculated.detail_inventory
                .cigars,
              inventoryComparisonData.data.inventory_form.detail_inventory
                .cigars
            ),
          },
          {
            label: "Cerveza",
            form: inventoryComparisonData.data.inventory_form.detail_inventory
              .beer,
            calculate:
              inventoryComparisonData.data.inventory_calculated.detail_inventory
                .beer,
            alert: alertType(
              inventoryComparisonData.data.inventory_calculated.detail_inventory
                .beer,
              inventoryComparisonData.data.inventory_form.detail_inventory.beer
            ),
          },
          {
            label: "Abarrotes",
            form: inventoryComparisonData.data.inventory_form.detail_inventory
              .groceries,
            calculate:
              inventoryComparisonData.data.inventory_calculated.detail_inventory
                .groceries,
            alert: alertType(
              inventoryComparisonData.data.inventory_calculated.detail_inventory
                .groceries,
              inventoryComparisonData.data.inventory_form.detail_inventory
                .groceries
            ),
          },
          {
            label: "Cremeria y lacteos",
            form: inventoryComparisonData.data.inventory_form.detail_inventory
              .dairy,
            calculate:
              inventoryComparisonData.data.inventory_calculated.detail_inventory
                .dairy,
            alert: alertType(
              inventoryComparisonData.data.inventory_calculated.detail_inventory
                .dairy,
              inventoryComparisonData.data.inventory_form.detail_inventory.dairy
            ),
          },
        ];

        return (
          <TableBody>
            {productData.map((data) => (
              <TableRow>
                <TableCell>
                  <Text className={classes.textCellTable}>{data.label}</Text>
                </TableCell>
                <TableCell>
                  <Text className={classes.textCellTable}>{data.form}</Text>
                </TableCell>
                <TableCell>
                  <Text className={classes.textCellTable}>
                    {data.calculate}
                  </Text>
                </TableCell>
                <TableCell>{data.alert}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        );
      }
    }
  };

  return (
    <Box display="flex" flexDirection="row">   
    <Box flex={1}>
    <Box style={{marginBottom: 12}}>
    {Object.keys(inventoryVersionData).length !== 0 && !isComparison ? (
      <ComparisonComponent onClick={handleButtonClick} />
    ) : Object.keys(inventoryVersionData).length !== 0 && isComparison && (
      <Box flexDirection={"row"} display={"flex"}>
        <InventoryDatePicker
          dateData={inventoryVersionData?.data}
          onSelectedItemChange={handleFirstItem}
          selectedValue={firstSelectedItemInfo}
          setSelectedValue={setFirstSelectedItemInfo}
        />
        <InventoryDatePicker
          dateData={inventoryVersionData?.data}
          onSelectedItemChange={handleSecondItem}
          selectedValue={setSecondSelectedItemInfo}
          setSelectedValue={setSecondSelectedItemInfo}
        />
      </Box>
    )}
    <Box display={'flex'} flexDirection={'row'}>
    <AdminAction 
    msgType={isError ? "error" : "success"}
    splashBtnText="Nueva oportunidad - Inventario"
    isLoading={loading?.POST_REEVALUATE_INVENTORY}
    onClickSplashBtn={() => setIsModalOpen(true)}
    handleCloseSnackbar={handleCloseSnackbar}
    isOpenSnackbar={isOpenInventorySnackbar}
    onClickModalBtn={() => sendReevaluateInventory(selectedReasonId)}
    snackBarMsg={isError ? error?.POST_REEVALUATE_INVENTORY?.response?.data
     ?.readable_message : "Oportunidad otorgada con éxito"}
     isOpenModal={isModalOpen}
     setOpenModal={setIsModalOpen}
     children={
     <FormControl style={{width:350}}>
     <TextField
     select
     label={"Razón de segunda oportunidad"}
     value={selectedReasonId}
     onChange={(e) => setSelectedReasonId(e.target.value)}
   >
   {filteredInventoryOptions
    ?.map((reason) => (
      <MenuItem key={reason.id} value={reason.id}>
        {reason.name}
      </MenuItem>
    ))}
   </TextField>
     </FormControl>
    }
    />
    <Box style={{marginLeft: '30px'}}>
      <AdminAction 
        msgType={isError ? "error" : "success"}
        splashBtnText="Nueva Versión - Inventario"
        isLoading={loading?.POST_INVENTORY_VERSION_CLOSING}
        onClickSplashBtn={() => sendInventoryVersionClosing()}
        handleCloseSnackbar={handleCloseSnackbar}
        isOpenSnackbar={isOpenInventoryClosingSnackbar}
        snackBarMsg={isError ? error?.POST_INVENTORY_VERSION_CLOSING?.response?.data
        ?.readable_message || 'Ocurrió un error' : "Nueva versión cerrada con éxito"}
      />
    </Box>
    <Box style={{marginLeft: '30px'}}>
      <AdminAction
        splashBtnText="Evaluación"
        onClickSplashBtn={() => setIsOpenEvaluationModal(true)}
        isOpenModal={isOpenEvaluationModal}
        setOpenModal={setIsOpenEvaluationModal}
        modalTitle={"Crear tarea de evaluación:"}
        isOpenSnackbar={isOpenTaskEvaluationSnackbar}
        handleCloseSnackbar={handleCloseSnackbar}
        isLoading={loading?.POST_TASK_ASSIGNMENT}
        msgType={isError ? "error" : "success"}
        snackBarMsg={isError ?  error?.POST_TASK_ASSIGNMENT?.response?.data?.readable_message : "Tarea de Evaluación creada con éxito"}
        onClickModalBtn={evaluationTaskCreated}
        isDisabledModalBtn={values.name_advisor !== "" && values.email_advisor !== "" && values.task_due_date !== "" ? false : true}
        children={
          <>
            <TextInput
              className={classes.formElement}
              label="Nombre completo del asesor"
              variant="standard"
              value={values.name_advisor}
              onChange={handleChangeInput("name_advisor")}
              onBlur={handleTrim("name_advisor")}
              inputProps={{ maxLength: 30, pattern: "w+(sw+)*" }}
              helperText={errorMessages.name_advisor}
              error={errorMessages.name_advisor !== "" ? true : false}
            />
            <TextInput
              className={classes.formElement}
              label="Correo del asesor"
              variant="standard"
              value={values.email_advisor}
              onChange={handleChangeInput("email_advisor")}
              onBlur={handleTrim("email_advisor")}
              inputProps={{ maxLength: 30, pattern: /^[a-zA-Z0-9._-]+@yofio\.co$/}}
              helperText={errorMessages.email_advisor}
              error={errorMessages.email_advisor !== "" ? true : false}
            />
            <TextInput
              id="task_due_date"
              label="Fecha de evaluación"
              type="date"
              value={values.task_due_date}
              onChange={handleChangeInput("task_due_date")}
              className={classes.formElement}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </>
        }
      />
    </Box>
    <Box style={{marginLeft: '30px'}}>
      <AdminAction 
        msgType={isError ? "error" : "success"}
        splashBtnText="Editar inventario"
        isLoading={loading?.POST_INVENTORY_CLOSING_FILL_FORM}
        onClickSplashBtn={() => setIsOpenEditInventoryModal(true)}
        handleCloseSnackbar={handleCloseSnackbar}
        isOpenSnackbar={isOpenEditInventorySnackBar}
        snackBarMsg={isError ? error?.POST_INVENTORY_CLOSING_FILL_FORM?.response?.data
        ?.readable_message || 'Ocurrió un error' : "Inventario editado con éxito"}
      />
      <Modal
        open={isOpenEditInventoryModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.modalEditInventory}
      >
        <Box className={classes.modalContainerEditInventory}>
          <Box className={classes.rowContainer}>
            <Text className={classes.titleModal}>Editar inventario</Text>
            <IconButton
              className={classes.buttonClose}
              onClick={() => setIsOpenEditInventoryModal(false)}
            >
              <Close style={{color: "#0253cc"}}/>
            </IconButton>
          </Box>
          <TextInput
            className={classes.formElementModal}
            label="¿Valor a editar?"
            variant="standard"
            select
            value={valuesModal.valueEdit}
            onChange={handleChangeInputModal("valueEdit")}
            helperText={errorMessagesModal.valueEdit}
            error={errorMessagesModal.valueEdit !== "" ? true : false}
          >
            <MenuItem value={"payments_financial_system"}>Pagos al Sist. Financiero</MenuItem>
            <MenuItem value={"passives"}>Pasivos</MenuItem>
          </TextInput>
          <TextInput
            className={classes.formElementModal}
            label="Monto"
            variant="standard"
            type="number"
            value={valuesModal.amount}
            onChange={handleChangeInputModal("amount")}
            helperText={errorMessagesModal.amount}
            error={errorMessagesModal.amount !== "" ? true : false}
          />
          <Box className={classes.buttonModalContainer}>
            <Button
              style={{backgroundColor: '#FFF', color: '#0253cc', fontWeight: '600', fontSize: '12px'}}
              onClick={() => setIsOpenEditInventoryModal(false)}
            >
              Cancelar
            </Button>
            <Button
              className={classes.buttonConfirmChange}
              variant="contained"
              color='secondary'
              onClick={sendEditInventory}
              isDisabled={(
                valuesModal.valueEdit !== "" &&
                valuesModal.amount !== "" ?
                false : true
              )}
            >
              Confirmar cambio
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
    </Box>
    </Box>
    {isComparison ? (
      <Box style={{display:"flex", justifyContent:"space-between", alignItems:"center", margin:12}}>
      {isComparison && firstSelectedItemInfo && secondSelectedItemInfo && (
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: 12,
          }}
        >
          <InventoryPdfViewer
            phone_number={phone_number}
            isComparison={isComparison}
            version={firstSelectedItemInfo.version}
            business_flow={
              isFirstItemOrigination ? "ORIGINATION" : "POST_ORIGINATION"
            }
          />
          <InventoryPdfViewer
            phone_number={phone_number}
            isComparison={isComparison}
            version={secondSelectedItemInfo.version}
            business_flow={
              isSecondItemOrigination ? "ORIGINATION" : "POST_ORIGINATION"
            }
          />
        </Box>
      )}
      </Box>
      
    ) : (
      <>
        {visibleTable()}
        <InventoryPdfViewer phone_number={phone_number} />
        {photoCategories && photoCategories.length > 0 && (
          <Box>
            <Box style={{ width: "65%", paddingTop: 15, paddingBottom: 15 }}>
              <Text variant="h4" className={classes.textBlue}>
                Categoría
              </Text>
              <Select
                displayEmpty
                style={{ display: "flex" }}
                labelId="demo-simple-select-label"
                id="filterCategory"
                value={filterCategory}
                onChange={(e) => setFilterCategory(e.target.value)}
              >
                <MenuItem value="" disabled>
                  Selecciona una categoría
                </MenuItem>
                {categoryList}
              </Select>
            </Box>
       {facadePhotoInfo &&  <ValidateFacadeComponent 
        isOpenModal={isOpenValidateFacadeModal} 
        setOpenModal={setIsOpenValidateFacadeModal} 
        onClickLink={() => setIsOpenValidateFacadeModal(true)}
        facadeStatus={facadeStatus}
        handleChangeFacadeStatus={handleChangeFacadeStatus}
        defaultFacadeStatus={facadePhotoInfo?.is_valid}
        onClickModalBtn={updateFacadeStatus}
        />}
          </Box>
          
        )}

        {filterCategory && filteredPhotos.length > 0 && (
          <>
            <Grid container direction="row" alignItems="flex-start">
              {filteredPhotos.map((data) => {
                return (
                  <Box key={data.id} className={classes.inventoryPhotosContainer}>
                    <img
                      src={data.url_signed}
                      className={classes.photoInventoryStyle}
                      onClick={() => setIsViewDetailPhoto({
                        show: true,
                        image: data.url_signed,
                        latitude: data.latitude,
                        longitude: data.longitude,
                      })}
                    />
                    <Box className={classes.rowButtonsContainer}>
                      <ButtonComponent 
                        style={{
                          backgroundColor: '#FFF', 
                          color: '#0253CC', 
                          fontWeight: '600', 
                          fontSize: '14px',
                        }}
                        onClick={() => setIsActionPhotoModal({
                          show: true,
                          action: "delete",
                          photoId: data.inventory_photo_id,
                        })}
                      >
                        Borrar foto
                      </ButtonComponent>
                      <ButtonComponent
                        style={{
                          backgroundColor: '#FFF', 
                          color: '#0253CC', 
                          fontWeight: '600', 
                          fontSize: '14px',
                        }}
                        onClick={() => setIsActionPhotoModal({
                          show: true,
                          action: "edit",
                          photoId: data.inventory_photo_id,
                        })}
                      >
                        Modificar categoría
                      </ButtonComponent>
                    </Box>
                  </Box>
                );
              })}
            </Grid>
            <Modal
              open={isViewDetailPhoto.show}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className={classes.modalEditInventory}
              onClose={() => setIsViewDetailPhoto({ 
                show: false,
                image: "",
                latitude: "",
                longitude: "", 
              })}
            >
              <Box className={classes.modalContainerViewPhoto}>
                <ImageWithPlaceholder src={isViewDetailPhoto.image} width={869.23}  height={650}/>
                {isViewDetailPhoto.latitude && isViewDetailPhoto.longitude && (
                  <Map
                    width={450}
                    height={650}
                    lat={parseFloat(isViewDetailPhoto.latitude)}
                    lng={parseFloat(isViewDetailPhoto.longitude)}
                  />
                )}
              </Box>
            </Modal>
            <Modal
              open={isActionPhotoModal.show}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className={classes.modalEditInventory}
              
            >
              <Box className={classes.modalActionPhotosContainer}>
                <Box className={classes.rowContainer} style={{marginBottom: 0}}>
                  <Text className={classes.titleModal}>
                    {isActionPhotoModal.action === "delete" ? "Borrar foto" : "Modificar categoría"}
                  </Text>
                  <IconButton
                    className={classes.buttonClose}
                    onClick={() => setIsActionPhotoModal({
                      show: false,
                      action: "",
                      photoId: "",
                    })}
                  >
                    <Close style={{color: "#0253cc"}}/>
                  </IconButton>
                </Box>
                <hr className={classes.dividerModal} />
                {isActionPhotoModal.action === "delete" ? 
                  <Text className={classes.textDeleteModal}>
                    Una vez borrada la foto no se puede recuperar
                  </Text> : 
                  <TextField
                    style={{marginTop: 10, marginBottom: 10}}
                    select
                    label={"¿A que categoría vas a agregar la foto?"}
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                  >
                    <MenuItem value="" disabled>
                      Selecciona una categoría
                    </MenuItem>
                    {photoCategories?.map((category) => (
                      <MenuItem key={category.id} value={category.category_photo}>
                        {category.category_photo_spanish}
                      </MenuItem>
                    ))}
                  </TextField>
                }
                <Box className={classes.buttonModalContainer}>
                  <Button
                    style={{backgroundColor: '#FFF', color: '#0253cc', fontWeight: '600', fontSize: '12px'}}
                    onClick={() => setIsActionPhotoModal({
                      show: false,
                      action: "",
                      photoId: "",
                    })}
                  >
                    Cancelar
                  </Button>
                  <Button
                    className={classes.buttonConfirmChange}
                    variant="contained"
                    color='secondary'
                    onClick={sendActionChangePhotoInventory}
                    isDisabled={isActionPhotoModal.action === "edit" ?(
                      category !== "" &&
                      isActionPhotoModal.action !== "delete" ?
                      false : true
                    ): false}
                  >
                    {isActionPhotoModal.action === "delete" ? "Borrar foto" : "Confirmar cambio"}
                  </Button>
                </Box>
              </Box>
            </Modal>
            <Snackbar open={isSnackBar.show}>
              <Alert severity="error" variant="filled">
                <AlertTitle>{"ERROR"}</AlertTitle>
                {isSnackBar.type === "delete" ? "Error al borrar la foto" : "Error al modificar la categoría"}
              </Alert>
            </Snackbar>
          </>
        )}
        {(!filterCategory || filteredPhotos.length === 0) && (
          <Box>
            <Text variant="h4" className={classes.textBlue}>
              No hay fotos / videos / documentos de inventario para la categoría
              seleccionada
            </Text>
          </Box>
        )}
      </>
    )}
    </Box>
    {isOpenApprovalTable &&
      <Box flex={1} marginLeft={15}>
      <ApprovalTableForm checkboxValues={checkboxValues} handleCheckboxChange={handleCheckboxChange} handleInputChange={handleVeredictInputChange} inputValues={veredictInputValues} shopkeeperInfo={shopkeeperInfo} inputErrors={veredictInputErrors}/>
      </Box>
    }
    </Box>
  );
};

export default InventoryTab;
