import React, { useEffect, useState } from 'react';
import {
    IconButton,
  Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '../../components/Layout';
import Text from '../../components/Text';
import { getLateFees } from '../../reducers/creditline.action';
import useStyles from './LateAmount.styles';
import { colorStatus, statusType } from '../../constants/constants';

const LateAmount = ({ creditLineId }) => {
  const { lateFeesRequestData } = useSelector((state) => state.creditLineReducer);
  const dispatch = useDispatch();
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    dispatch(getLateFees(creditLineId));
  }, [creditLineId]);

  useEffect(() => {
    setPage(0);
}, [lateFeesRequestData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const calculateTotalPages = () => {
    return Math.ceil((lateFeesRequestData?.data?.Payload.length || 0) / rowsPerPage);
  };

  const headCells = [
    { id: 'late_date', label: 'Fecha de Generación' },
    { id: 'installment', label: 'Cuota para cálculo de moratorio' },
    { id: 'late_percentage', label: 'Porcentaje de mora' },
    { id: 'late_interest', label: 'Interés moratorio' },
    { id: 'late_vat', label: 'IVA moratorio' },
    { id: 'status', label: 'Estatus' },
    { id: 'remaining_late_interest', label: 'Interés moratorio restante' },
    { id: 'remaining_late_vat', label: 'IVA moratorio restante' },
  ];

  const getStatusStyles = (status) => {
    switch (status) {
      case 'PENDING':
        return { style: {color: colorStatus[status] , textAlign:'center'} ,label: statusType[status] };
      case 'APPROVED':
        return { style: {color: colorStatus[status], textAlign:'center'}, label: statusType[status]  };
      case 'REJECTED':
        return { style: {color: colorStatus[status], textAlign:'center'}, label: statusType[status]  };
      default:
        return { style: {color: colorStatus[status], textAlign:'center'}, label: statusType[status] };
    }
  };

  return (
    <Box>
    <Text className={classes.tableTitle}>Detalle de mora</Text>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell key={headCell.id} align="center">
                  <Text>{headCell.label}</Text>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
          {lateFeesRequestData?.data && (
            <>
              {( rowsPerPage > 0 
                ? lateFeesRequestData?.data?.Payload?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : lateFeesRequestData?.data?.Payload
            ). map((info, index)  => (
                <TableRow key={index} style={{ maxHeight: 43 }}>
                  <TableCell>
                    <Text className={classes.textStyle}>
                      {info?.late_date ? info?.late_date : '-'}
                    </Text>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                  <Text className={classes.textStyle}>
                  ${info?.installment ? (info?.installment).toFixed(2) : '0'}
                  </Text>
                </TableCell>
                <TableCell>
                  <Text className={classes.textStyle}>
                  %{info?.late_percentage ? info?.late_percentage : '0'}
                  </Text>
                </TableCell>
                <TableCell>
                  <Text className={classes.textStyle}>
                  ${info?.late_interest ? (info?.late_interest).toFixed(2) : '0'}
                  </Text>
                </TableCell>
                <TableCell>
                  <Text className={classes.textStyle}>
                  ${info?.late_vat ? (info?.late_vat).toFixed(2) : '0'}
                  </Text>
                </TableCell>
                <TableCell>
                  <Text style={getStatusStyles(info?.status).style}>
                  {getStatusStyles(info?.status).label}
                  </Text>
                </TableCell>
                <TableCell>
                  <Text className={classes.textStyle}>
                  ${info?.remaining_late_interest ? (info?.remaining_late_interest).toFixed(2) : '0'}
                  </Text>
                </TableCell>
                <TableCell>
                  <Text className={classes.textStyle}>
                  ${info?.remaining_late_vat ? (info?.remaining_late_vat).toFixed(2) : '0'}
                  </Text>
                </TableCell>
                </TableRow>
              ))}
            </>
          )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={lateFeesRequestData?.data?.Payload?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="Registros por página:"
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
        />
      </TableContainer>
    </Box>
  );
};

export default LateAmount;
