import * as ACTION from './users.actionTypes';
import * as usersService from '../services/users.service';

export const putUpdateMaritalStatus = (userType, userId, body) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_PUT_UPDATE_MARITAL_STATUS,
  });

  try {
    const maritalStatusData = await usersService.putUpdateMaritalStatus(userType, userId, body);
    if (maritalStatusData) {
      dispatch({
        type: ACTION.RESPONSE_PUT_UPDATE_MARITAL_STATUS,
        result: maritalStatusData,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_PUT_UPDATE_MARITAL_STATUS,
      error,
    });
  }
};

export const putUpdateRequirements = (userId, body) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_PUT_UPDATE_REQUIREMENTS,
  });

  try {
    const requirementsData = await usersService.putUpdateRequirements(userId, body);
    if (requirementsData) {
      dispatch({
        type: ACTION.RESPONSE_PUT_UPDATE_REQUIREMENTS,
        result: requirementsData,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_PUT_UPDATE_REQUIREMENTS,
      error,
    });
  }
};

export const getBureaus = (userType, userId) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_GET_BUREAUS,
  });

  try {
    const bureausData = await usersService.getBureaus(userType, userId);
    if (bureausData) {
      dispatch({
        type: ACTION.RESPONSE_GET_BUREAUS,
        result: bureausData,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_GET_BUREAUS,
      error,
    });
  }
};

export const getComparsionAlerts = (userId) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_GET_COMPARISON_ALERTS,
  });

  try {
    const alertsData = await usersService.getComparsionAlerts(userId);
    if (alertsData) {
      dispatch({
        type: ACTION.RESPONSE_GET_COMPARISON_ALERTS,
        result: alertsData,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_GET_COMPARISON_ALERTS,
      error,
    });
  }
};

export const putVeredict = (userType, userId, body) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_PUT_VEREDICT,
  });

  try {
    const veredictData = await usersService.putVeredict(userType, userId, body);
    if (veredictData) {
      dispatch({
        type: ACTION.RESPONSE_PUT_VEREDICT,
        result: veredictData,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_PUT_VEREDICT,
      error,
    });
  }
};

export const postResumeRegister = (userType, userId, body) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_POST_RESUME_REGISTER,
  });

  try {
    const resumeRegisterData = await usersService.postResumeRegister(userType, userId, body);
    if (resumeRegisterData) {
      dispatch({
        type: ACTION.RESPONSE_POST_RESUME_REGISTER,
        result: resumeRegisterData,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_POST_RESUME_REGISTER,
      error,
    });
  }
};

export const postRejectRegister = (userType, userId, body) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_POST_REJECT_REGISTER,
  });

  try {
    const rejectRegisterData = await usersService.postRejectRegister(userType, userId, body);
    if (rejectRegisterData) {
      dispatch({
        type: ACTION.RESPONSE_POST_REJECT_REGISTER,
        result: rejectRegisterData,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_POST_REJECT_REGISTER,
      error,
    });
  }
};

export const getUsers = (userId, userType) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_GET_USERS,
  });

  try {
    const getUsersData = await usersService.getUsers(userId, userType);
    if (getUsersData) {
      dispatch({
        type: ACTION.RESPONSE_GET_USERS,
        result: getUsersData,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_GET_USERS,
      error,
    });
  }
};

export const putAssignPassword = (userId,userType, body) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_PUT_ASSIGN_PASSWORD,
  });

  try {
    const assignPasswordData = await usersService.assignPassword(userId,userType,body);
    if (assignPasswordData) {
      dispatch({
        type: ACTION.RESPONSE_PUT_ASSIGN_PASSWORD,
        result: assignPasswordData,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_PUT_ASSIGN_PASSWORD,
      error,
    });
  }
};

export const putLightCreditLine = (userType, userId) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_PUT_LIGHT_CREDIT_LINE,
  });

  try {
    const lightCreditLineData = await usersService.putLightCreditline(userType, userId);
    if (lightCreditLineData) {
      dispatch({
        type: ACTION.RESPONSE_PUT_LIGHT_CREDIT_LINE,
        result: lightCreditLineData,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_PUT_LIGHT_CREDIT_LINE,
      error,
    });
  }
};

// Change bank account
export const putChangeBankAccount = (account_type_id, account_id, body) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_PUT_CHANGE_BANK_ACCOUNT,
  });

  try {
    const putChangeBankAccountData = await usersService.putChangeBankAccount(account_type_id, account_id, body);
    if (putChangeBankAccountData) {
      dispatch({
        type: ACTION.RESPONSE_PUT_CHANGE_BANK_ACCOUNT,
        result: putChangeBankAccountData,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_PUT_CHANGE_BANK_ACCOUNT,
      error,
    });
  }
};

export const clearPutChangeBankAccount = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_PUT_CHANGE_BANK_ACCOUNT,
  });
};

export const clearPutUpdateMaritalStatus = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_PUT_UPDATE_MARITAL_STATUS,
  });
};

export const clearPutUpdateRequirements = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_PUT_UPDATE_REQUIREMENTS,
  });
};

export const clearGetBureaus = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_GET_BUREAUS,
  });
};

export const clearGetComparisonAlerts = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_GET_COMPARISON_ALERTS,
  });
};

export const clearPutVeredict = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_PUT_VEREDICT,
  });
};

export const clearPostResumeRegister = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_POST_RESUME_REGISTER,
  });
};

export const clearPostRejectRegister = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_POST_REJECT_REGISTER,
  });
};

export const clearGetUsers = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_GET_USERS,
  });
};

export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_ERRORS,
  });
};
